<template>
  <v-row class="d-flex justify-start mt-0">
    <!-- FILTROS -->
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-form @submit.prevent="buscar()">
            <v-row class="d-flex align-center">
              <v-col cols="12" sm="3" md="2" class="pt-1">
                Periodo
                <PeriodoPicker v-model="filtro.periodo" />
              </v-col>
              <v-col cols="12" sm="3" md="2" class="pt-1">
                Empresa
                <v-autocomplete
                  v-model="filtro.empresa"
                  item-value="id"
                  item-text="nombre"
                  :items="familias"
                  hide-details
                  outlined
                  dense
                  @change="set_marca()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="2" class="pt-1">
                Marca
                <v-autocomplete
                  v-model="filtro.marca"
                  item-value="id"
                  item-text="nombre"
                  :items="
                    empresas.filter((mar) => mar.familia == filtro.empresa)
                  "
                  hide-details
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="6" class="d-flex justify-end pb-sm-0">
                <v-btn
                  fab
                  class="mr-2"
                  color="grey darken-1"
                  max-width="40"
                  max-height="40"
                  @click="abrir_modal()"
                >
                  <v-icon class="white--text">fas fa-cog</v-icon>
                </v-btn>

                <v-btn color="info" type="submit">
                  <v-icon small left>fas fa-search</v-icon>
                  Buscar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- INCENTIVOS -->
    <v-col cols="12" class="pt-0">
      <v-data-table
        class="cebra elevation-2"
        :headers="headers"
        :items="incentivos"
        :loading="load"
        :items-per-page="-1"
        :item-class="itemRowBackgroundDetalle"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <div class="text-h5 px-2 py-1">Incentivos</div>
        </template>
        <template v-slot:[`item.Nombre_incentivo`]="{ item, value }">
          <v-btn
            @click="redirect_cuota(filtro.empresa, filtro.marca, item.Avance_grupo)"
            x-small
            color="transparent"
            >{{ value }}
            <v-icon class="ml-1" x-small>fas fa-external-link-alt</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.Tolerancia`]="{ value }">
          {{ value }} %
        </template>
        <template v-slot:[`item.Cobro`]="{ value }"> {{ value }} % </template>
        <template v-slot:[`item.Caida_real`]="{ value }">
          {{ value }} %
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- MODAL OBJETIVOS -->
    <Objetivos
      :empresa="filtro.empresa"
      :marca="filtro.marca"
      :periodo="filtro.periodo"
      v-model="dialog"
      @buscar="buscar_actualizados"
    />
  </v-row>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import PeriodoPicker from "../../components/util/PeriodoPicker.vue";
import Objetivos from "../../components/planes/Dashboard/Objetivos.vue";

export default {
  data() {
    return {
      dialog: false,
      load: false,
      incentivos: [],
      headers: [
        { text: "Incentivo", value: "Nombre_incentivo", avance: "Avance_grupo" },
        { text: "Avance", value: "Avance_grupo", align: "center" },
        { text: "Clientes", value: "Total_clientes", align: "center" },
        { text: "Tolerancia", value: "Tolerancia", align: "center" },
        { text: "Cobro", value: "Cobro", align: "center" },
        { text: "Objetivo", value: "Objetivo", align: "center" },
        { text: "Pagados", value: "Total_pagados", align: "center" },
        { text: "Diferencia", value: "Diferencia", align: "center" },
        { text: "Caida real", value: "Caida_real", align: "center" },
        { text: "Controla", value: "Controla", align: "center" },
      ],
      filtro: {
        periodo: moment(new Date()).format("MM/YYYY"),
        empresa: null,
        marca: null,
      },
    };
  },
  created() {
    // si tiene solo 1 empresa la selecciono
    if (this.una_familia) this.filtro.empresa = this.familias[0].id;
    // si tiene solo 1 marca la selecciono
    this.set_marca();
  },
  computed: {
    ...mapState(["familias", "empresas"]),
    ...mapGetters(["una_familia"]),
  },
  components: {
    PeriodoPicker,
    Objetivos,
  },
  methods: {
    async buscar() {
      if (!this.filtro.periodo || !this.filtro.marca) {
        return this.$store.dispatch("show_snackbar", {
          text: "Debe seleccionar un periodo y una marca",
          color: "orange",
        });
      }

      this.incentivos = [];

      this.load = true;
      let result = await this.$store.dispatch(
        "dashboardPA/get_incentivos",
        this.filtro
      );
      this.load = false;

      if (result.exito == 1) {
        this.incentivos = result.data;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    redirect_cuota(empresa, marca, avance) {
      const query = `?empresa=${encodeURIComponent(
        empresa
      )}&marca=${encodeURIComponent(marca)}&avance=${encodeURIComponent(avance)}`;

      // Construir la URL completa con la cadena de consulta
      const url = "/planes/mantenimiento" + query;

      // Abrir la URL en una nueva ventana
      window.open(url, "_blank"); // El segundo parámetro '_blank' indica abrir en una nueva ventana
    },

    set_marca() {
      let marca = this.empresas.filter(
        (mar) => mar.familia == this.filtro.empresa
      );
      this.filtro.marca = marca.length == 1 ? marca[0].id : null;
    },

    itemRowBackgroundDetalle(item) {
      return item.Diferencia > 0
        ? "background-color: rowwar"
        : "background-color: rowsuc";
    },

    abrir_modal() {
      this.dialog = true;
    },

    buscar_actualizados(valor){
      if(valor && this.filtro.periodo != null && this.filtro.marca != null){
        this.buscar()
      }
    }
  },

};
</script>

<template>
  <v-dialog
    v-model="dialog"
    width="900"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        <span :class="$vuetify.breakpoint.xs ? 'body-1' : ''">
          Suscripción {{ detalle.preimpreso }} - {{ detalle.grupo }}/{{ detalle.orden }}
        </span>
        <v-spacer></v-spacer>
        <template
          v-if="p_editar || (estados_mant.find(em => em.id == detalle.estado_id) && estados_mant.find(em => em.id == detalle.estado_id).mantenimiento == 1 && estados_mant.find(em => em.id == detalle.estado_id).no_edita != 1)"
        >
          <v-switch
            v-model="editar"
            class="mt-n1 ml-4"
            label="Editar"
            hide-details
          ></v-switch>
          <v-divider 
            class="mx-3"
            vertical
          ></v-divider>
        </template>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-6 body-1">
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Titular
                </v-col>
                <v-col cols="9">
                  <strong>{{ detalle.titular }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  E-mail
                </v-col>
                <v-col cols="9">
                  <strong>{{ detalle.contacto_email }}</strong>
                  <v-btn
                    v-if="detalle.contacto_email != null && detalle.contacto_email != ''"
                    title="Copiar al portapapeles"
                    small
                    icon
                    @click="copy_clipboard(detalle.contacto_email)"
                  >
                    <v-icon small>far fa-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-1">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Vehículo
                </v-col>
                <v-col cols="9">
                  <strong>{{ detalle.vehiculo }}</strong>
                  <v-tooltip
                    v-if="detalle.adjudicado != null"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="warning"
                        class="ml-2"
                        small
                      >
                        fas fa-key
                      </v-icon>
                    </template>
                    <div>
                      {{ detalle.adjudicado }}
                    </div>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-1">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Mes Inaug.
                </v-col>
                <v-col cols="9">
                  <strong>{{ formatMesAño(detalle.inaugurado) }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-1">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Vendedor
                </v-col>
                <v-col cols="9">
                  <strong>{{ detalle.vendedor }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" sm="3" class="py-1">
              <v-row>
                <v-col cols="6" class="pr-0 pl-md-3 pl-0">
                  Avance
                </v-col>
                <v-col cols="6">
                  <strong>{{ detalle.avance }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" sm="3" class="py-1">
              <v-row>
                <v-col cols="6" class="pr-0 pl-md-3 pl-0">
                  Impagas
                </v-col>
                <v-col cols="6">
                  <strong>{{ detalle.cuota_vencida }}</strong>
                  <v-tooltip
                    v-if="detalle.detalle_impagas != null && detalle.detalle_impagas != ''"
                    right
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="info"
                        class="mb-1"
                        small
                        right
                      >
                        fas fa-info-circle
                      </v-icon>
                    </template>
                    <span
                      v-html="detalle.detalle_impagas"
                    ></span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Forma Pago
                </v-col>
                <v-col cols="9">
                  <strong>{{ detalle.forma_pago }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-2 pb-0">
              <v-row>
                <v-col cols="3" sm="4" class="pr-0 pl-md-3 pl-0">
                  Motivo no pago
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    v-model="motivo"
                    :items="motivos_np"
                    :clearable="editar"
                    :readonly="!editar"
                    :filled="!editar"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="pt-2 pb-0">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Estado
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    v-if="editar"
                    v-model="estado"
                    item-value="id"
                    item-text="nombre"
                    :items="p_editar ? estados_mant : estados_mant.filter(em => em.mantenimiento == 1)"
                    :readonly="(detalle.estado_id == 5 && detalle.situacion_xpansiva == 'RENUNCIADO') || (detalle.estado_id == 15 && detalle.situacion_xpansiva == 'TERMINADO') || detalle.liquidado == 1 || (detalle.estado_id == 16 && detalle.situacion_xpansiva == 'PRELEGAL')"
                    :filled="(detalle.estado_id == 5 && detalle.situacion_xpansiva == 'RENUNCIADO') || (detalle.estado_id == 15 && detalle.situacion_xpansiva == 'TERMINADO') || detalle.liquidado == 1 || (detalle.estado_id == 16 && detalle.situacion_xpansiva == 'PRELEGAL')"
                    outlined
                    dense
                    @change="validarContactar()"
                  ></v-autocomplete>
                  <strong v-else>{{ detalle.estado_nombre }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="pt-2 pb-0">
              <v-row>
                <v-col cols="3" sm="4" class="pr-0 pl-md-3 pl-0">
                  Asignado a
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    v-model="usuario_asignado"
                    item-value="id"
                    item-text="nombre"
                    :items="usuarios_asignar.filter(us => (us.inhabilitado != 1 || us.id == usuario_asignado) && us.empresas.find(em => em == detalle.empresa_id))"
                    :clearable="editar && p_editar"
                    :readonly="!(editar && p_editar)"
                    :filled="!(editar && p_editar)"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0 pt-2">
              <v-textarea
                v-model="observacion"
                label="Observación"
                :rows="3"
                :readonly="!editar"
                :filled="!editar"
                auto-grow
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <!-- log comentarios -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="comentarios"
          :loading="load_com"
          :search="search"
          :footer-props="{
            'items-per-page-options': [10, 50, 100]
          }"
          dense
        >
          <template v-slot:top>
            <v-row class="px-2 pb-2">
              <v-col cols="8" class="d-flex align-center body-1">
                Historial de observaciones
              </v-col>
              <v-col cols="4">
                <SearchDataTable v-model="search"/>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.fecha`]="{ value }">
            {{ moment(value).add(3, 'hours').format('DD/MM/YY [-] HH:mm') }}
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="300"
              type="warning"
              border="left"
              dense
              text
            >
              Sin comentarios
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pt-0 pb-4">
        <v-row class="d-flex align-center ma-0">
          <v-checkbox
            v-if="show_contactar"
            v-model="contactar"
            label="Volver a contactar"
            class="mb-1"
            :true-value="1"
            :false-value="null"
            :disabled="!editar"
            hide-details
            dense
          ></v-checkbox>
          <v-btn
            title="Ver historial"
            color="indigo"
            class="ml-4 mr-2"
            icon
            @click="dialog_log = true"
          >
            <v-icon>fas fa-history</v-icon>
          </v-btn>
          Ult. actualiz. {{ moment(detalle.fecha_actualiz).add(3, 'hours').format('DD/MM/YY [-] HH:mm') }}
          <v-spacer></v-spacer>
          <v-btn
            :color="editar ? 'error' : 'secondary'"
            class="mr-2"
            :disabled="load"
            @click="dialog = false"
          >
            {{ editar ? 'Cancelar' : 'Volver' }}
          </v-btn>
          <BtnConfirmar
            v-if="editar"
            :loading="load"
            @action="guardar()"
          />
        </v-row>
      </v-card-actions>
    </v-card>
    <!-- componentes -->
    <Historial
      v-model="dialog_log"
      :preimpreso="detalle.preimpreso"
      :empresa="detalle.empresa_id"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { formatMesAño, format_money } from '../../util/utils'
import SearchDataTable from '../util/SearchDataTable.vue'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import Historial from './mantenimiento/Historial.vue'
import moment from 'moment'

export default {
  data () {
    return {
      formatMesAño: formatMesAño,
      formatMoney: format_money,
      moment: moment,
      show_contactar: false,
      dialog_log: false,
      load_com: false,
      load: false,
      editar: false,
      search: '',
      estado: null,
      motivo: null,
      usuario_asignado: null,
      observacion: null,
      contactar: null,
      comentarios: [],
      headers: [
        { text: 'Observación', value: 'comentario', sortable: false },
        { text: 'Circuito', value: 'circuito_nombre', sortable: false },
        { text: 'Fecha', value: 'fecha', sortable: false, width: '150' },
        { text: 'Usuario', value: 'username', sortable: false, width: '150' }
      ]
    }
  },
  props: {
    value: Boolean,
    detalle: Object
  },
  computed: {
    ...mapState('mantenimientoPA', ['estados_mant', 'usuarios_asignar', 'motivos_np']),
    ...mapState('recuperos', ['p_editar']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    SearchDataTable,
    BtnConfirmar,
    Historial
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.load_com = true
        let result = await this.$store.dispatch('mantenimientoPA/get_comentarios', this.detalle.id)
        this.load_com = false

        if (result.exito == 1) {
          this.comentarios = result.data
        } else {
          this.$swal.fire({
            icon: 'error',
            title: result.message
          })
        }
        this.setearDatos()
      } else {
        this.comentarios = []
        this.search = ''
        this.editar = false
      }
    },
    editar (val) {
      val || this.setearDatos()
    }
  },
  methods: {
    async guardar () {
      let datos = {
        cartera_id: this.detalle.id,
        estado: this.estado,
        motivo: this.motivo,
        usuario_asignado: this.usuario_asignado,
        observacion: this.observacion,
        contactar: this.contactar
      }
      
      this.load = true
      let result = await this.$store.dispatch(`mantenimientoPA/editar_detalle`, datos)
      this.load = false

      if (result.exito == 1) {
        this.$emit('setear', datos)
        this.dialog = false
        this.$swal.fire({
          icon: 'success',
          title: result.message
        })
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }
    },
    validarContactar () {
      const estado = this.estados_mant.find(em => em.id == this.estado)
      if (estado && estado.contactar == 1) {
        this.show_contactar = true
        this.contactar = JSON.parse(JSON.stringify(this.detalle.contactar))
      } else {
        this.show_contactar = false
        this.contactar = null
      }
    },
    copy_clipboard (texto) {
      navigator.clipboard.writeText(texto)
    },
    setearDatos () {
      this.estado = JSON.parse(JSON.stringify(this.detalle.estado_id))
      this.motivo = JSON.parse(JSON.stringify(this.detalle.motivo_np))
      this.observacion = null
      this.usuario_asignado = JSON.parse(JSON.stringify(this.detalle.usuario_asignado_id))
      this.validarContactar()
    }
  }
}
</script>
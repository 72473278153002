<template>
  <v-dialog
    v-model="dialog"
    width="700"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        Asignación Masiva por Campaña
        <v-spacer></v-spacer>
        <v-chip
          color="lime"
          light
        >
          {{ empresa ? $store.state.empresas.find(em => em.id == empresa).nombre : '' }}
        </v-chip>
        <v-divider 
          class="mx-3"
          vertical
        ></v-divider>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          class="mt-2"
          :headers="[
            { text: 'Usuario', value: 'nombre', sortable: false, width: 250 },
            { text: 'Campaña', value: 'avances', sortable: false }
          ]"
          :items="usuarios"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:[`item.avances`]="{ item }">
            <v-autocomplete
              v-model="item.avances"
              :items="cuotas.filter(cuota => {
                let existe = false
                usuarios.forEach(us => {
                  if (us.avances.find(av => av == cuota.value) && us.id != item.id) {
                    existe = true
                  }
                })
                if (!existe) return cuota
              })"
              class="my-1"
              deletable-chips
              hide-details
              small-chips
              multiple
              outlined
              dense
            ></v-autocomplete>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pt-0 pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../../util/BtnConfirmar.vue'

export default {
  data () {
    return {
      load: false,
      cuotas: [],
      usuarios: []
    }
  },
  async created () {
    for (let i = 2; i <= 120; i++) {
      this.cuotas.push({
        value: i,
        text: 'CUOTA ' + i
      })
    }
  },
  props: {
    value: Boolean,
    empresa: Number
  },
  computed: {
    ...mapState('mantenimientoPA', ['usuarios_asignar']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        for (const usuario of this.usuarios_asignar.filter(us => us.inhabilitado != 1 && us.empresas.find(em => em == this.empresa))) {
          this.usuarios.push({
            id: usuario.id,
            nombre: usuario.nombre,
            avances: []
          })
        }
      } else {
        this.usuarios = []
      }
    }
  },
  methods: {
    async guardar () {
      this.load = true
      let result = await this.$store.dispatch('mantenimientoPA/asignar_masivo', {
        empresa: this.empresa,
        usuarios: this.usuarios.map(item => {
          if (item.avances.length > 0) {
            return {
              id: item.id,
              avances: item.avances
            }
          }
        }).filter(us => us != null)
      })
      this.load = false

      if (result.exito == 1) {
        this.$emit('refrescar')
        this.dialog = false
        this.$swal.fire({
          icon: 'success',
          title: result.message
        })
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }
    }
  }
}
</script>
<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        Nuevo comentario
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-6">
        <v-textarea
          v-model.trim="comentario"
          label="Observaciones"
          rows="3"
          hide-details
          auto-grow
          outlined
          dense
        ></v-textarea>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          :disabled="comentario == ''"
          :loading="load"
          @click="guardar()"
        >
          <v-icon left>fas fa-save</v-icon>
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      load: false,
      comentario: ''
    }
  },
  props: {
    value: Boolean,
    cartera_id: Number,
    circuito: Number
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      val || (this.comentario = '')
    }
  },
  methods: {
    async guardar () {
      if (this.comentario == '') {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar un comentario',
          color: 'orange'
        })
      }

      this.load = true
      let result = await this.$store.dispatch('carteraPA/nuevo_comentario', {
        id: this.cartera_id,
        circuito: this.circuito,
        comentario: this.comentario
      })
      this.load = false

      if (result.exito == 1) {
        this.dialog = false
        this.$emit('actualizar')
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'success'
        })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    }
  }
}
</script>
<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-value="id"
                    item-text="nombre"
                    :items="familias"
                    hide-details
                    outlined
                    dense
                    @change="set_empresa()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Marca
                  <v-autocomplete
                    v-model="filtro.marca"
                    item-value="id"
                    item-text="nombre"
                    :items="empresas.filter(emp => emp.familia == filtro.empresa)"
                    hide-details
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Suscripción
                  <v-text-field
                    v-model.trim="filtro.preimpreso"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Grupo
                  <v-text-field
                    v-model.trim="filtro.grupo"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Orden
                  <v-text-field
                    v-model.trim="filtro.orden"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" class="py-1">
                  Nº Documento
                  <v-text-field
                    v-model.trim="filtro.documento"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Titular
                  <v-text-field
                    v-model.trim="filtro.titular"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="titular"
        :headers="headers"
        :items="cartera"
        :loading="load"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 50, 100],
          'show-first-last-page': true,
          'show-current-page': true
        }"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable v-model="search"/>
            </v-col>
          </v-row>
        </template>
        <!-- campos formateados -->
        <template v-slot:[`item.cuota_vencida`]="{ item }">
          {{ item.cuota_vencida }}
          <v-tooltip
            v-if="item.detalle_impagas != null && item.detalle_impagas != ''"
            right
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="info"
                small
                right
              >
                fas fa-info-circle
              </v-icon>
            </template>
            <span
              v-html="item.detalle_impagas"
            ></span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.existe_sai`]="{ value }">
          <v-tooltip
            v-if="value != 1"
            left
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="orange"
                class="ml-n4"
                small
              >
                fas fa-exclamation-triangle
              </v-icon>
            </template>
            <div>
              Este plan no se encuentra cargado en AutoDealer
            </div>
          </v-tooltip>
        </template>
        <template v-slot:[`item.inaugurado`]="{ value }">
          {{ formatMesAño(value) }}
        </template>
        <template v-slot:[`item.vehiculo`]="{ item }">
          <v-tooltip
            v-if="item.vehiculo != null && item.vehiculo != ''"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="teal"
                small
              >
                fas fa-car
              </v-icon>
            </template>
            <div>
              {{ item.vehiculo }}
            </div>
          </v-tooltip>
          <v-tooltip
            v-if="item.adjudicado != null"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="warning"
                class="ml-2"
                small
              >
                fas fa-key
              </v-icon>
            </template>
            <div>
              {{ item.adjudicado }}
            </div>
          </v-tooltip>
        </template>
        <template v-slot:[`item.adjudicado`]="{ value }">
          <td class="d-none">{{ value }}</td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="cartera_item = item; dialog_detalle = true"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            color="purple"
            title="Comentarios"
            class="mr-2"
            small
            @click="cartera_item = item; dialog_comentario = true"
          >
            fas fa-comment-dots
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- componentes -->
    <DetalleCartera
      v-model="dialog_detalle"
      :detalle="cartera_item"
    />
    <ModalObservaciones
      v-model="dialog_comentario"
      :detalle="cartera_item"
      :circuito="4"
    />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formatMesAño } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import SearchDataTable from '../../components/util/SearchDataTable'
import DetalleCartera from '../../components/planes/DetalleCartera.vue'
import ModalObservaciones from '../../components/planes/ModalObservaciones.vue'
import moment from 'moment'

export default {
  data () {
    return {
      formatMesAño: formatMesAño,
      moment: moment,
      panel: 0,
      load: false,
      dialog_detalle: false,
      dialog_comentario: false,
      search: '',
      cartera_item: {},
      cartera: [],
      headers: [
        { text: 'Suscripción', value: 'preimpreso', align: 'right' },
        { text: 'Grupo/Orden', value: 'control_gr', sortable: false },
        { text: 'Avance', value: 'avance', align: 'center' },
        { text: 'Impagas', value: 'cuota_vencida', align: 'center' },
        { text: 'Titular', value: 'titular' },
        { text: 'DNI', value: 'dni' },
        { text: 'CUIT', value: 'cuit' },
        { text: 'Marca', value: 'marca_nombre' },
        { text: 'Vehículo', value: 'vehiculo', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
        { text: '', value: 'existe_sai', align: 'center', width: '1' },
        { text: '', value: 'adjudicado', class: 'd-none', sortable: false }
      ],
      filtro: {
        empresa: null,
        marca: null,
        preimpreso: '',
        grupo: '',
        orden: '',
        titular: '',
        documento: ''
      }
    }
  },
  async created () {
    // si tiene solo 1 empresa la selecciono
    if (this.una_familia) this.filtro.empresa = this.familias[0].id
    // si tiene solo 1 marca la selecciono
    this.set_empresa()
  },
  computed: {
    ...mapGetters(['una_familia']),
    ...mapState(['empresas', 'familias'])
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    DetalleCartera,
    ModalObservaciones
  },
  methods: {
    async buscar () {
      if (!this.filtro.empresa) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'orange'
        })
      }

      let header = this.headers.find(he => he.value == 'marca_nombre')
      if (this.filtro.marca && header) {
        this.headers.splice(7, 1)
      } else if (!this.filtro.marca && !header) {
        this.headers.splice(7, 0, { text: 'Marca', value: 'marca_nombre' })
      }

      this.cartera = []
      this.search = ''

      this.load = true
      let result = await this.$store.dispatch('carteraPA/get_cartera', this.filtro)
      this.load = false

      if (result.exito == 1) {
        this.cartera = result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    set_empresa () {
      let marcas = this.empresas.filter(emp => emp.familia == this.filtro.empresa)
      this.filtro.marca = marcas.length == 1 ? marcas[0].id : null
    },
    limpiar () {
      this.filtro = {
        empresa: null,
        marca: null,
        preimpreso: '',
        grupo: '',
        orden: '',
        titular: '',
        documento: ''
      }
    }
  }
}
</script>
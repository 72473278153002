<template>
    <v-dialog
    v-model="dialog"
    width="900"
    scrollable
  >
    <v-card>
      <v-card-title class="pl-3 pr-4">
        <span :class="$vuetify.breakpoint.xs ? 'body-1' : ''">
          Suscripción {{ detalle.preimpreso }} - {{ detalle.grupo }}/{{ detalle.orden }}
        </span>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <SearchDataTable v-model="search"/>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-0 pb-0">
        <v-data-table
          class="cebra"
          :headers="headers"
          :items="comentarios"
          :loading="load"
          :search="search"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:top>
            <v-row class="mx-0 mt-2 mb-1">
              <v-col cols="12" sm="8" class="d-flex align-center text-h6 font-weight-normal py-1">
                Historial de observaciones
              </v-col>
              <v-col cols="12" sm="4" class="d-flex justify-end py-1">
                <v-btn
                  color="success"
                  title="Nuevo comentario"
                  class="ml-2"
                  small
                  @click="dialog_com = true"
                >
                  Nuevo comentario
                  <v-icon right>fas fa-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.fecha`]="{ value }">
            {{ moment(value).add(3, 'hours').format('DD/MM/YY [-] HH:mm') }}
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="300"
              type="warning"
              border="left"
              dense
              text
            >
              Sin comentarios
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="secondary"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- nuevo comentario -->
    <NuevaObservacion
      v-model="dialog_com"
      :cartera_id="detalle.id"
      :circuito="circuito"
      @actualizar="get_comentarios()"
    />
  </v-dialog>
</template>

<script>
import moment from 'moment'
import SearchDataTable from '../util/SearchDataTable.vue'
import NuevaObservacion from './NuevaObservacion.vue'

export default {
  data () {
    return {
      moment: moment,
      load: false,
      dialog_com: false,
      search: '',
      comentarios: [],
      headers: [
        { text: 'Observación', value: 'comentario', sortable: false },
        { text: 'Circuito', value: 'circuito_nombre', sortable: false },
        { text: 'Fecha', value: 'fecha', sortable: false, width: '150' },
        { text: 'Usuario', value: 'username', sortable: false, width: '150' }
      ]
    }
  },
  props: {
    value: Boolean,
    detalle: Object,
    circuito: Number
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    SearchDataTable,
    NuevaObservacion
  },
  watch: {
    dialog (val) {
      if (val) this.get_comentarios()
    }
  },
  methods: {
    async get_comentarios () {
      this.search = ''
      this.comentarios = []

      this.load = true
      let result = await this.$store.dispatch('mantenimientoPA/get_comentarios', this.detalle.id)
      this.load = false

      if (result.exito == 1) {
        this.comentarios = result.data
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }
    }
  }
}
</script>
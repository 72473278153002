var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"700","persistent":_vm.load,"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"disabled":_vm.load}},[_c('v-card-title',[_vm._v(" Asignación Masiva por Campaña "),_c('v-spacer'),_c('v-chip',{attrs:{"color":"lime","light":""}},[_vm._v(" "+_vm._s(_vm.empresa ? _vm.$store.state.empresas.find(em => em.id == _vm.empresa).nombre : '')+" ")]),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"mt-2",attrs:{"headers":[
          { text: 'Usuario', value: 'nombre', sortable: false, width: 250 },
          { text: 'Campaña', value: 'avances', sortable: false }
        ],"items":_vm.usuarios,"items-per-page":-1,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:`item.avances`,fn:function({ item }){return [_c('v-autocomplete',{staticClass:"my-1",attrs:{"items":_vm.cuotas.filter(cuota => {
              let existe = false
              _vm.usuarios.forEach(us => {
                if (us.avances.find(av => av == cuota.value) && us.id != item.id) {
                  existe = true
                }
              })
              if (!existe) return cuota
            }),"deletable-chips":"","hide-details":"","small-chips":"","multiple":"","outlined":"","dense":""},model:{value:(item.avances),callback:function ($$v) {_vm.$set(item, "avances", $$v)},expression:"item.avances"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"d-flex justify-end pt-0 pb-4"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","disabled":_vm.load},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('BtnConfirmar',{attrs:{"loading":_vm.load},on:{"action":function($event){return _vm.guardar()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
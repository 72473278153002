<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    scrollable
  >
    <v-card>
      <v-card-title class="py-2">
        <span :class="$vuetify.breakpoint.xs ? 'body-1' : ''">
          Suscripción {{ detalle.preimpreso }} - {{ detalle.grupo }}/{{ detalle.orden }}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-2">
        <v-form ref="form">
          <v-row class="pt-4 body-1">
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Titular
                </v-col>
                <v-col cols="9">
                  <strong>{{ detalle.titular }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  E-mail
                </v-col>
                <v-col cols="9">
                  <strong>{{ detalle.contacto_email }}</strong>
                  <v-btn
                    v-if="detalle.contacto_email != null && detalle.contacto_email != ''"
                    title="Copiar al portapapeles"
                    small
                    icon
                    @click="copy_clipboard(detalle.contacto_email)"
                  >
                    <v-icon small>far fa-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-1">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Vehículo
                </v-col>
                <v-col cols="9">
                  <strong>{{ detalle.vehiculo }}</strong>
                  <v-tooltip
                    v-if="detalle.adjudicado != null"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="warning"
                        class="ml-2"
                        small
                      >
                        fas fa-key
                      </v-icon>
                    </template>
                    <div>
                      {{ detalle.adjudicado }}
                    </div>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="3" class="py-1">
              <v-row>
                <v-col cols="6" class="pr-0 pl-md-3 pl-0">
                  Mes Inaug.
                </v-col>
                <v-col cols="6">
                  <strong>{{ formatMesAño(detalle.inaugurado) }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="3" class="py-1">
              <v-row>
                <v-col cols="6" class="px-0">
                  <v-row>
                    <v-col cols="6" class="pr-0 pl-3">
                      Avance
                    </v-col>
                    <v-col cols="6">
                      <strong>{{ detalle.avance }}</strong>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="px-0">
                  <v-row>
                    <v-col cols="7" class="pr-0 pl-3">
                      Impagas
                    </v-col>
                    <v-col cols="5">
                      <strong>{{ detalle.cuota_vencida }}</strong>
                      <v-tooltip
                        v-if="detalle.detalle_impagas != null && detalle.detalle_impagas != ''"
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="info"
                            class="mb-1"
                            small
                            right
                          >
                            fas fa-info-circle
                          </v-icon>
                        </template>
                        <span
                          v-html="detalle.detalle_impagas"
                        ></span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-1">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Vendedor
                </v-col>
                <v-col cols="9">
                  <strong>{{ detalle.vendedor }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Forma Pago
                </v-col>
                <v-col cols="9">
                  <strong>{{ detalle.forma_pago }}</strong>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <!-- log comentarios -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers_com"
          :items="comentarios"
          :loading="load_com"
          :search="search_com"
          :footer-props="{
            'items-per-page-options': [10, 50, 100]
          }"
          dense
        >
          <template v-slot:top>
            <v-row class="px-2 pt-2 pb-3">
              <v-col cols="8" class="d-flex align-center body-1 py-0">
                Historial de observaciones
                <v-btn
                  color="success"
                  class="ml-2"
                  title="Nuevo comentario"
                  icon
                  @click="dialog_com = true"
                >
                  <v-icon>fas fa-plus</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="4" class="py-0">
                <SearchDataTable v-model="search_com"/>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.fecha`]="{ value }">
            {{ moment(value).add(3, 'hours').format('DD/MM/YY [-] HH:mm') }}
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="300"
              type="warning"
              border="left"
              dense
              text
            >
              Sin comentarios
            </v-alert>
          </template>
        </v-data-table>
        <!-- pagos -->
        <v-data-table
          class="elevation-2 mt-2"
          :loading="load_pago"
          :items="pagos"
          :headers="headers_pago"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:top>
            <div class="d-flex mx-2">
              <span class="body-1 pt-2">
                Pagos de cuotas realizados por
                {{ familias.find(fam => fam.id == detalle.empresa_id) ? familias.find(fam => fam.id == detalle.empresa_id).nombre : '' }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.saldo_deudor`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.monto_empresa`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.observaciones`]="{ value }">
            <v-icon
              v-if="value != null && value != ''"
              color="info"
              title="Ver observación"
              small
              @click="$swal.fire({
                icon: 'info',
                text: value
              })"
            >
              fas fa-comment-dots
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron pagos
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        Última actualización el {{ moment(detalle.fecha_actualiz).add(3, 'hours').format('DD/MM/YYYY [a las] HH:mm') }} hs
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          class="mr-2"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- nuevo comentario -->
    <NuevaObservacion
      v-model="dialog_com"
      :cartera_id="detalle.id"
      :circuito="4"
      @actualizar="get_comentarios()"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { formatMesAño, format_money } from '../../util/utils'
import SearchDataTable from '../util/SearchDataTable.vue'
import NuevaObservacion from './NuevaObservacion.vue'
import moment from 'moment'

export default {
  data () {
    return {
      formatMesAño: formatMesAño,
      formatMoney: format_money,
      moment: moment,
      dialog_com: false,
      load_pago: false,
      load_com: false,
      search_pago: '',
      search_com: '',
      comentarios: [],
      pagos: [],
      headers_pago: [
        { text: 'Fecha', value: 'fecha', sortable: false, filterable: false },
        { text: 'Avance', value: 'avance', sortable: false, filterable: false },
        { text: 'Impagas', value: 'cuotas_impagas', sortable: false, filterable: false },
        { text: 'Saldo deudor', value: 'saldo_deudor', sortable: false, filterable: false },
        { text: 'Monto pagado', value: 'monto_empresa', sortable: false, filterable: false },
        { text: 'Cant. pagada', value: 'cantidad', sortable: false, filterable: false },
        { text: 'Observaciones', value: 'observaciones', sortable: false, filterable: false, align: 'center' }
      ],
      headers_com: [
        { text: 'Observación', value: 'comentario', sortable: false },
        { text: 'Circuito', value: 'circuito_nombre', sortable: false },
        { text: 'Fecha', value: 'fecha', sortable: false, width: '150' },
        { text: 'Usuario', value: 'username', sortable: false, width: '150' }
      ]
    }
  },
  props: {
    value: Boolean,
    detalle: Object
  },
  computed: {
    ...mapState(['familias']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    SearchDataTable,
    NuevaObservacion
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.get_comentarios()
        this.get_pagos()
      }
    }
  },
  methods: {
    async get_comentarios () {
      this.comentarios = []
      this.search_com = ''

      this.load_com = true
      let result = await this.$store.dispatch('mantenimientoPA/get_comentarios', this.detalle.id)
      this.load_com = false

      if (result.exito == 1) {
        this.comentarios = result.data
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }
    },
    async get_pagos () {
      this.pagos = []
      this.search_pago = ''
      
      this.load_pago = true
      let result = await this.$store.dispatch('mantenimientoPA/get_pagos', this.detalle.id)
      this.load_pago = false

      if (result.exito == 1) {
        this.pagos = result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    copy_clipboard (texto) {
      navigator.clipboard.writeText(texto)
    }
  }
}
</script>
<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.familia"
                    item-value="id"
                    item-text="nombre"
                    :items="familias"
                    hide-details
                    outlined
                    dense
                    @change="set_empresa()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Marca
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-value="id"
                    item-text="nombre"
                    :items="empresas.filter(emp => emp.familia == filtro.familia)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <template v-if="rangoAvance">
                  <v-col cols="6" sm="3" md="2" class="py-1">
                    Avance desde
                    <v-autocomplete
                      v-model="filtro.avance_desde"
                      :items="avances"
                      hide-details
                      clearable
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-1">
                    Avance hasta
                    <v-autocomplete
                      v-model="filtro.avance_hasta"
                      :items="avances"
                      hide-details
                      clearable
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                </template>
                <v-col v-else cols="6" sm="3" md="2" class="py-1">
                  Avance
                  <v-autocomplete
                    v-model="filtro.avance"
                    :items="avances"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Rango
                  <v-checkbox
                    v-model="rangoAvance"
                    class="mt-0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3" md="2" class="py-1">
                  Suscripción
                  <v-text-field
                    v-model.trim="filtro.preimpreso"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Grupo
                  <v-text-field
                    v-model.trim="filtro.grupo"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Orden
                  <v-text-field
                    v-model.trim="filtro.orden"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-value="id"
                    item-text="nombre"
                    :items="estados_recupero"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Situación
                  <v-autocomplete
                    v-model="filtro.situacion"
                    :items="situaciones"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="4" class="py-1">
                  Usuario asignado
                  <v-autocomplete
                    v-model="filtro.usuario"
                    :items="usuarios_asignar.filter(us => us.inhabilitado != 1 && (us.empresas.find(em => em == filtro.empresa) || filtro.empresa == null))"
                    item-value="id"
                    item-text="nombre"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="preimpreso"
        :headers="headers"
        :items="recuperos"
        :loading="load"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 50, 100],
          'show-first-last-page': true,
          'show-current-page': true
        }"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable v-model="search"/>
            </v-col>
          </v-row>
        </template>
        <!-- campos formateados -->
        <template v-slot:[`item.cuota_vencida`]="{ item }">
          {{ item.cuota_vencida }}
          <v-tooltip
            v-if="item.detalle_impagas != null && item.detalle_impagas != ''"
            right
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="info"
                small
                right
              >
                fas fa-info-circle
              </v-icon>
            </template>
            <span
              v-html="item.detalle_impagas"
            ></span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.existe_sai`]="{ value }">
          <v-tooltip
            v-if="value != 1"
            left
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="orange"
                class="ml-n4"
                small
              >
                fas fa-exclamation-triangle
              </v-icon>
            </template>
            <div>
              Este plan no se encuentra cargado en AutoDealer
            </div>
          </v-tooltip>
        </template>
        <template v-slot:[`item.inaugurado`]="{ value }">
          {{ formatMesAño(value) }}
        </template>
        <template v-slot:[`item.estado_nombre`]="{ item }">
          <v-chip
            :color="item.estado_color"
            :dark="item.estado_dark == 1"
            :light="item.estado_dark != 1"
            small
          >
            {{ item.estado_nombre }}
          </v-chip>
        </template>
        <template v-slot:[`item.asignado`]="{ item }">
          <v-icon v-if="item.usuario_asignado_id != null" color="success" small>fas fa-check-circle</v-icon>
        </template>
        <!-- acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_dialog(item)"
          >
            fas fa-eye
          </v-icon>
          <v-tooltip
            v-if="item.contactar == 1"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="orange"
                class="mr-2"
                small
              >
                fas fa-headset
              </v-icon>
            </template>
            <div>
              Volver a contactar
            </div>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <Recupero
      v-model="dialog_recupero"
      :recupero="item"
      @setear="setRecupero"
      @contactar="setContactar"
    />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formatMesAño, getNombreObjArray } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import SearchDataTable from '../../components/util/SearchDataTable'
import Recupero from '../../components/planes/Recupero.vue'

export default {
  data () {
    return {
      formatMesAño: formatMesAño,
      panel: 0,
      load: false,
      rangoAvance: false,
      dialog_recupero: false,
      search: '',
      item: {},
      avances: [],
      recuperos: [],
      headers: [
        { text: 'Suscripción', value: 'preimpreso', align: 'right' },
        { text: 'Grupo/Orden', value: 'control_gr', sortable: false },
        { text: 'Avance', value: 'avance', align: 'center' },
        { text: 'Impagas', value: 'cuota_vencida', align: 'center' },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Situación', value: 'situacion_xpansiva' },
        { text: 'Titular', value: 'titular' },
        { text: 'Estado pre', value: 'situacion_nombre' },
        { text: 'Marca', value: 'empresa_nombre' },
        { text: 'Asignado', value: 'asignado', align: 'center' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
        { text: '', value: 'existe_sai', align: 'center', width: '1' }
      ],
      filtro: {
        familia: null,
        empresa: null,
        avance: null,
        avance_desde: null,
        avance_hasta: null,
        preimpreso: '',
        grupo: '',
        orden: '',
        estado: null,
        situacion: null,
        usuario: null
      }
    }
  },
  async created () {
    // si tiene solo 1 familia la selecciono
    if (this.una_familia) this.filtro.familia = this.familias[0].id
    // si tiene solo 1 empresa la selecciono
    this.set_empresa()
    for (let i = 2; i <= 120; i++) {
      this.avances.push({
        value: i,
        text: 'CUOTA ' + i
      })
    }
    this.$store.state.loading = true
    await this.$store.dispatch('recuperos/get_estados')
    await this.$store.dispatch('recuperos/get_situaciones')
    await this.$store.dispatch('recuperos/get_usuarios')
    await this.$store.dispatch('recuperos/get_permiso_editar')
    this.$store.state.loading = false
  },
  computed: {
    ...mapGetters(['una_familia']),
    ...mapState(['empresas', 'familias']),
    ...mapState('recuperos', ['estados_recupero', 'situaciones', 'usuarios_asignar'])
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    Recupero
  },
  watch: {
    rangoAvance (val) {
      this.filtro.avance_hasta = null
      if (val) {
        this.filtro.avance_desde = JSON.parse(JSON.stringify(this.filtro.avance))
        this.filtro.avance = null
      } else {
        this.filtro.avance = JSON.parse(JSON.stringify(this.filtro.avance_desde))
        this.filtro.avance_desde = null
      }
    }
  },
  methods: {
    async buscar () {
      if (!this.filtro.familia) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'orange'
        })
      }

      let header = this.headers.find(he => he.value == 'empresa_nombre')
      if (this.filtro.empresa && header) {
        this.headers.splice(8, 1)
      } else if (!this.filtro.empresa && !header) {
        this.headers.splice(8, 0, { text: 'Marca', value: 'empresa_nombre' })
      }

      this.recuperos = []
      this.search = ''

      this.load = true
      let result = await this.$store.dispatch('recuperos/get_recuperos', this.filtro)
      this.load = false

      if (result.exito == 1) {
        this.recuperos = result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    setRecupero (recupero) {
      let cartera = this.recuperos.find(ca => ca.id == recupero.cartera_id)
      cartera.estado_id = recupero.estado
      cartera.estado_nombre = getNombreObjArray(this.estados_recupero, 'id', recupero.estado)
      cartera.estado_color = getNombreObjArray(this.estados_recupero, 'id', recupero.estado, 'color')
      cartera.estado_dark = getNombreObjArray(this.estados_recupero, 'id', recupero.estado, 'dark')
      cartera.usuario_asignado_id = recupero.usuario_asignado
      cartera.monto_recupero = recupero.monto_empresa
      cartera.negociacion = recupero.negociacion
      cartera.contactar = recupero.contactar
      cartera.recupero_id = recupero.id
    },
    setContactar (cartera_rec) {
      let cartera = this.recuperos.find(ca => ca.id == cartera_rec.id)
      cartera.usuario_asignado_id = cartera_rec.usuario
      cartera.contactar = 1
    },
    open_dialog (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.dialog_recupero = true
    },
    set_empresa () {
      let empresa = this.empresas.filter(emp => emp.familia == this.filtro.familia)
      this.filtro.empresa = empresa.length == 1 ? empresa[0].id : null
    },
    limpiar () {
      this.filtro = {
        familia: null,
        empresa: null,
        avance: null,
        avance_desde: null,
        avance_hasta: null,
        preimpreso: '',
        grupo: '',
        orden: '',
        estado: null,
        situacion: null,
        usuario: null
      }
    }
  }
}
</script>
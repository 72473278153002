<template>
  <v-menu
    close-on-click
    offset-x
    top
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip
        v-if="item.alerta == 1 && item.forma_pago != null"
        bottom
      >
        <template v-slot:activator="{ on: tooltip }">
          <v-icon
            v-on="{ ...tooltip, ...menu }"
            v-bind="attrs"
            color="error"
            class="mr-2"
            small
            @click="verificarNumeros()"
          >
            {{ item.forma_pago.substring(0, 2) == 'TR' ? 'fas fa-envelope' : 'fab fa-whatsapp' }}
          </v-icon>
        </template>
        {{ item.forma_pago.substring(0, 2) == 'TR' ? 'Enviar cupón' : 'Llamar cliente' }}
      </v-tooltip>
      <v-tooltip
        v-else-if="item.contacto_telefono != null && item.contacto_telefono != ''"
        bottom
      >
        <template v-slot:activator="{ on: tooltip }">
          <v-icon
            v-on="{ ...tooltip, ...menu }"
            v-bind="attrs"
            color="wpp"
            class="mr-2"
            small
            @click="verificarNumeros()"
          >
            fab fa-whatsapp
          </v-icon>
        </template>
        Enviar mensaje
      </v-tooltip>
    </template>
    <v-list v-if="(item.contacto_telefono ? item.contacto_telefono.toString().split('/') : []).length > 1">
      <v-list-item
        v-for="(telefono, index) in item.contacto_telefono ? item.contacto_telefono.toString().split('/') : []"
        :key="index"
        @click="mandarWpp(telefono)"
      >
        <v-list-item-title>{{ telefono }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    item: Object
  },
  methods: {
    mandarWpp (numero) {
      window.open(`https://wa.me/549${numero.trim()}`, '_blank')
    },
    verificarNumeros () {
      if (!this.item.contacto_telefono) return
      let numeros = this.item.contacto_telefono.toString().split('/')

      switch (numeros.length) {
        case 0:
          this.$store.dispatch('show_snackbar', {
            text: 'El cliente no posee un teléfono',
            color: 'info'
          })
          break
        case 1:
          this.mandarWpp(numeros[0])
          break
      }
    }
  }
}
</script>
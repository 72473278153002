<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ item.usuario_asignado_id == null ? 'A' : 'Rea' }}signar Suscripción
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" sm="6" class="py-1">
              <v-autocomplete
                v-model="usuario"
                label="Usuario"
                item-value="id"
                item-text="nombre"
                :items="usuarios_asignar.filter(us => (us.inhabilitado != 1 || us.id == usuario) && us.empresas.find(em => em == item.empresa_id))"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="py-1">
              <v-text-field
                v-model="area"
                label="Área"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pt-0 pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          :loading="load"
          @click="guardar()"
        >
          <v-icon small left>fas fa-user-plus</v-icon>
          {{ item.usuario_asignado_id == null ? 'A' : 'Rea' }}signar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      load: false,
      usuario: null,
      area: 'MANTENIMIENTO'
    }
  },
  props: {
    value: Boolean,
    item: Object
  },
  computed: {
    ...mapState('mantenimientoPA', ['usuarios_asignar']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.usuario = JSON.parse(JSON.stringify(this.item.usuario_asignado_id))
      } else {
        this.usuario = null
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (!this.$refs.form.validate()) return
      
      if (this.item.usuario_asignado_id == this.usuario) {
        this.dialog = false
        return this.$store.dispatch('show_snackbar', {
          text: 'No se realizaron cambios',
          color: 'info'
        })
      }

      this.load = true
      let result = await this.$store.dispatch('mantenimientoPA/asignar', {
        cartera_id: this.item.id,
        usuario_asignado: this.usuario
      })
      this.load = false

      if (result.exito == 1) {
        this.$emit('asignar', {
          id: this.item.id,
          usuario: this.usuario
        })
        this.dialog = false
        this.$swal.fire({
          icon: 'success',
          title: result.message
        })
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }
    }
  }
}
</script>
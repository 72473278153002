<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-scale-transition origin="center center">
            <v-btn
              v-if="p_descargar"
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="info"
              title="Descargar"
              small
              fab
              @click="dialog_descargar = true"
            >
              <v-icon>fas fa-file-download</v-icon>
            </v-btn>
          </v-scale-transition>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.familia"
                    item-value="id"
                    item-text="nombre"
                    :items="familias"
                    hide-details
                    outlined
                    dense
                    @change="set_empresa()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Marca
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-value="id"
                    item-text="nombre"
                    :items="empresas.filter(emp => emp.familia == filtro.familia)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <template v-if="rangoAvance">
                  <v-col cols="6" sm="3" md="2" class="py-1">
                    Avance desde
                    <v-autocomplete
                      v-model="filtro.avance_desde"
                      :items="avances"
                      hide-details
                      clearable
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-1">
                    Avance hasta
                    <v-autocomplete
                      v-model="filtro.avance_hasta"
                      :items="avances"
                      hide-details
                      clearable
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                </template>
                <v-col v-else cols="6" sm="3" md="2" class="py-1">
                  Avance
                  <v-autocomplete
                    v-model="filtro.avance"
                    :items="avances"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Rango
                  <v-checkbox
                    v-model="rangoAvance"
                    class="mt-0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Suscripción
                  <v-text-field
                    v-model.trim="filtro.preimpreso"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Grupo
                  <v-text-field
                    v-model.trim="filtro.grupo"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Orden
                  <v-text-field
                    v-model.trim="filtro.orden"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="preimpreso"
        :headers="headers"
        :items="ventas"
        :loading="load"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 50, 100],
          'show-first-last-page': true,
          'show-current-page': true
        }"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable v-model="search"/>
            </v-col>
          </v-row>
        </template>
        <!-- campos formateados -->
        <template v-slot:[`item.existe_sai`]="{ value }">
          <v-tooltip
            v-if="value != 1"
            left
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="orange"
                class="ml-n4"
                small
              >
                fas fa-exclamation-triangle
              </v-icon>
            </template>
            <div>
              Este plan no se encuentra cargado en AutoDealer
            </div>
          </v-tooltip>
        </template>
        <template v-slot:[`item.fecha_ingreso`]="{ value }">
          {{ moment(value).format('DD/MM/YYYY') }}
        </template>
        <template v-slot:[`item.precio_vehiculo`]="{ value }">
          {{ formatMoneyRound(value) }}
        </template>
        <!-- acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="amber"
            title="Vender"
            class="mr-2"
            small
            @click="registo = item; dialog_venta = true"
          >
            fas fa-key
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <Venta
      v-model="dialog_venta"
      :cartera="registo"
      @accion="eliminarRegistro()"
    />
    <ExcelVentas v-model="dialog_descargar" />
  </v-row>
</template>

<script>
import moment from 'moment'
import { format_money_round } from '../../util/utils'
import { mapState, mapGetters } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro'
import SearchDataTable from '../../components/util/SearchDataTable'
import Venta from '../../components/planes/Venta.vue'
import ExcelVentas from '../../components/planes/ExcelVentas.vue'

export default {
  data () {
    return {
      formatMoneyRound: format_money_round,
      moment: moment,
      panel: 0,
      load: false,
      rangoAvance: false,
      dialog_venta: false,
      dialog_descargar: false,
      search: '',
      registo: {},
      avances: [],
      ventas: [],
      headers: [
        { text: 'Suscripción', value: 'preimpreso', align: 'right' },
        { text: 'Grupo/Orden', value: 'control_gr', sortable: false },
        { text: 'Avance', value: 'avance', align: 'center' },
        { text: 'Situación', value: 'situacion_xpansiva' },
        { text: 'Fecha Ingreso', value: 'fecha_ingreso' },
        { text: 'Vehículo', value: 'vehiculo' },
        { text: 'Total cuotas', value: 'meses_nombre' },
        { text: 'Marca', value: 'empresa_nombre' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
        { text: '', value: 'existe_sai', align: 'center', width: '1' }
      ],
      filtro: {
        familia: null,
        empresa: null,
        avance: null,
        avance_desde: null,
        avance_hasta: null,
        preimpreso: '',
        grupo: '',
        orden: ''
      }
    }
  },
  async created () {
    // si tiene solo 1 familia la selecciono
    if (this.una_familia) this.filtro.familia = this.familias[0].id
    // si tiene solo 1 empresa la selecciono
    this.set_empresa()
    for (let i = 1; i <= 120; i++) {
      this.avances.push({
        value: i,
        text: 'CUOTA ' + i
      })
    }
    this.$store.state.loading = true
    await this.$store.dispatch('ventasPA/get_permiso_descargar')
    this.$store.state.loading = false
  },
  computed: {
    ...mapGetters(['una_familia']),
    ...mapState(['empresas', 'familias']),
    ...mapState('ventasPA', ['p_descargar'])
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    Venta,
    ExcelVentas
  },
  watch: {
    rangoAvance (val) {
      this.filtro.avance_hasta = null
      if (val) {
        this.filtro.avance_desde = JSON.parse(JSON.stringify(this.filtro.avance))
        this.filtro.avance = null
      } else {
        this.filtro.avance = JSON.parse(JSON.stringify(this.filtro.avance_desde))
        this.filtro.avance_desde = null
      }
    }
  },
  methods: {
    async buscar () {
      if (!this.filtro.familia) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'orange'
        })
      }

      let header = this.headers.find(he => he.value == 'empresa_nombre')
      if (this.filtro.empresa && header) {
        this.headers.splice(7, 1)
      } else if (!this.filtro.empresa && !header) {
        this.headers.splice(7, 0, { text: 'Marca', value: 'empresa_nombre' })
      }

      this.ventas = []
      this.search = ''

      this.load = true
      let result = await this.$store.dispatch('ventasPA/get_ventas', this.filtro)
      this.load = false

      if (result.exito == 1) {
        this.ventas = result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    eliminarRegistro () {
      const index = this.ventas.indexOf(this.registo)
      this.ventas.splice(index, 1)
    },
    set_empresa () {
      let empresa = this.empresas.filter(emp => emp.familia == this.filtro.familia)
      this.filtro.empresa = empresa.length == 1 ? empresa[0].id : null
    },
    limpiar () {
      this.filtro = {
        familia: null,
        empresa: null,
        avance: null,
        avance_desde: null,
        avance_hasta: null,
        preimpreso: '',
        grupo: '',
        orden: ''
      }
    }
  }
}
</script>
<template>
  <v-dialog
    v-model="dialog"
    width="900"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        <span :class="$vuetify.breakpoint.xs ? 'body-1' : ''">
          Suscripción {{ cartera.preimpreso }} - {{ cartera.grupo }}/{{ cartera.orden }}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" @submit.prevent="{}">
          <v-row class="pt-6 body-1">
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Vehículo
                </v-col>
                <v-col cols="9">
                  <strong>{{ cartera.vehiculo }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" sm="3" class="py-2">
              <v-row>
                <v-col cols="6" class="pr-0 pl-md-3 pl-0">
                  Avance
                </v-col>
                <v-col cols="6">
                  <strong>{{ cartera.avance }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" sm="3" class="py-1">
              <v-row>
                <v-col cols="6" class="pr-0 pl-md-3 pl-0">
                  Impagas
                </v-col>
                <v-col cols="6">
                  <strong>{{ cartera.cuota_vencida }}</strong>
                  <v-tooltip
                    v-if="cartera.detalle_impagas != null && cartera.detalle_impagas != ''"
                    right
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="info"
                        class="mb-1"
                        small
                        right
                      >
                        fas fa-info-circle
                      </v-icon>
                    </template>
                    <span
                      v-html="cartera.detalle_impagas"
                    ></span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" sm="4" class="pr-0 pl-md-3 pl-0">
                  Fecha ingreso
                </v-col>
                <v-col cols="8">
                  <strong>{{ moment(cartera.fecha_ingreso).format('DD/MM/YYYY') }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" sm="4" class="pr-0 pl-md-3 pl-0">
                  Precio sugerido
                </v-col>
                <v-col cols="8">
                  <strong>{{ formatMoneyRound(cartera.precio_sugerido) }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" sm="4" class="pr-0 pl-md-3 pl-0">
                  Vendedor
                </v-col>
                <v-col cols="8">
                  <strong v-if="es_vendedor">{{ vendedor_nombre }}</strong>
                  <v-autocomplete
                    v-else
                    v-model="vendedor"
                    item-value="id"
                    item-text="nombre"
                    :items="vendedores"
                    :rules="[$store.state.rules.required]"
                    validate-on-blur
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" sm="4" class="pr-0 pl-md-3 pl-0">
                  Monto venta
                </v-col>
                <v-col cols="8" md="6">
                  <TextFieldMoney
                    v-model="monto_venta"
                    v-bind:properties="{
                      'prefix': '$',
                      'rules': [$store.state.rules.required],
                      'validate-on-blur': true
                    }"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="comentarios"
                label="Comentario"
                :rows="3"
                auto-grow
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pt-0 pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          icono="fas fa-handshake"
          nombre="Vender Plan"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { format_money_round } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import TextFieldMoney from '../util/TextFieldMoney.vue'

export default {
  data () {
    return {
      formatMoneyRound: format_money_round,
      moment: moment,
      load: false,
      es_vendedor: false,
      vendedor_nombre: '',
      vendedor: null,
      monto_venta: null,
      comentarios: null
    }
  },
  props: {
    value: Boolean,
    cartera: Object
  },
  computed: {
    ...mapState('ventasPA', ['vendedores']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    TextFieldMoney
  },
  watch: {
    async dialog (val) {
      if (val) {
        await new Promise(resolve => setTimeout(resolve, 1))

        this.$store.state.loading = true
        let result = await this.$store.dispatch('ventasPA/verificar_usuario_vendedor')
        this.$store.state.loading = false

        if (result.exito == 1) {
          // si la consulta devolvio 1 es pq el usuario es vendedor
          if (result.data.length == 1) {
            this.es_vendedor = true
            this.vendedor = result.data[0].id
            this.vendedor_nombre = result.data[0].nombre
          }
          // sino obtengo los vendedores
          else {
            this.$store.state.loading = true
            await this.$store.dispatch('ventasPA/get_vendedores')
            this.$store.state.loading = false
          }
        } else {
          this.dialog = false
          this.$swal.fire({
            icon: 'error',
            title: result.message
          })
        }
      } else {
        this.es_vendedor = false
        this.vendedor_nombre = ''
        this.vendedor = null
        this.monto_venta = null
        this.comentarios = null
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (!this.$refs.form.validate()) return

      this.load = true
      let result = await this.$store.dispatch(`ventasPA/nueva_venta`, {
        cartera_id: this.cartera.id,
        vendedor: this.vendedor,
        monto_venta: this.monto_venta,
        comentarios: this.comentarios
      })
      this.load = false

      if (result.exito == 1) {
        this.$emit('accion')
        this.dialog = false
        this.$swal.fire({
          icon: 'success',
          title: result.message
        })
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }
    }
  }
}
</script>
<template>
  <v-dialog
    v-model="dialog"
    width="900"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        <span :class="$vuetify.breakpoint.xs ? 'body-1' : ''">
          Suscripción {{ recupero.preimpreso }} - {{ recupero.grupo }}/{{ recupero.orden }}
        </span>
        <v-spacer></v-spacer>
        <v-chip
          :color="recupero.situacion_color"
          :dark="recupero.situacion_dark == 1"
          :light="recupero.situacion_dark != 1"
        >
          {{ recupero.situacion_nombre }}
        </v-chip>
        <v-switch
          v-if="(recupero.estado_id == 1 && (recupero.situacion_id == 4 || recupero.situacion_id == 6)) || p_editar"
          v-model="editar"
          class="mt-n1 ml-4"
          label="Editar"
          hide-details
        ></v-switch>
        <v-divider 
          class="mx-3"
          vertical
        ></v-divider>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-6 body-1">
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Titular
                </v-col>
                <v-col cols="9">
                  <strong>{{ recupero.titular }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  E-mail
                </v-col>
                <v-col cols="9">
                  <strong>{{ recupero.contacto_email }}</strong>
                  <v-btn
                    v-if="recupero.contacto_email != null && recupero.contacto_email != ''"
                    title="Copiar al portapapeles"
                    small
                    icon
                    @click="copy_clipboard(recupero.contacto_email)"
                  >
                    <v-icon small>far fa-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-1">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Vehículo
                </v-col>
                <v-col cols="9">
                  <strong>{{ recupero.vehiculo }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" class="pr-0 pl-sm-3 pl-0">
                  Mes Inaug.
                </v-col>
                <v-col cols="9">
                  <strong>{{ formatMesAño(recupero.inaugurado) }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-2">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Forma Pago
                </v-col>
                <v-col cols="9">
                  <strong>{{ recupero.forma_pago }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" sm="3" class="py-1">
              <v-row>
                <v-col cols="6" class="pr-0 pl-sm-3 pl-0">
                  Avance
                </v-col>
                <v-col cols="6">
                  <strong>{{ recupero.avance }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" sm="3" class="py-1">
              <v-row>
                <v-col cols="6" class="pr-0 pl-md-3 pl-0">
                  Impagas
                </v-col>
                <v-col cols="6">
                  <strong>{{ recupero.cuota_vencida }}</strong>
                  <v-tooltip
                    v-if="recupero.detalle_impagas != null && recupero.detalle_impagas != ''"
                    right
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="info"
                        class="mb-1"
                        small
                        right
                      >
                        fas fa-info-circle
                      </v-icon>
                    </template>
                    <span
                      v-html="recupero.detalle_impagas"
                    ></span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="pt-2 pb-0">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Estado
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    v-model="estado"
                    item-value="id"
                    item-text="nombre"
                    :items="estados_recupero"
                    :readonly="!editar || (recupero.estado_id == 5 && recupero.situacion_xpansiva == 'RENUNCIADO') || (recupero.estado_id == 15 && recupero.situacion_xpansiva == 'TERMINADO')"
                    :filled="!editar || (recupero.estado_id == 5 && recupero.situacion_xpansiva == 'RENUNCIADO') || (recupero.estado_id == 15 && recupero.situacion_xpansiva == 'TERMINADO')"
                    outlined
                    dense
                    @change="estado == 5 ? monto_empresa = null : ''; validarContactar()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="pt-2 pb-0">
              <v-row>
                <v-col cols="3" sm="4" class="pr-0 pl-sm-3 pl-0">
                  Saldo Deudor
                </v-col>
                <v-col cols="9" sm="8">
                  <strong>{{ formatMoney(recupero.saldo_deudor) }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-row>
                <v-col cols="3" class="pr-0 pl-md-3 pl-0">
                  Asignado a
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    v-if="!editar"
                    v-model="recupero.usuario_asignado_id"
                    item-value="id"
                    item-text="nombre"
                    :items="usuarios_asignar.filter(us => (us.inhabilitado != 1 || us.id == recupero.usuario_asignado_id) && us.empresas.find(em => em == recupero.empresa_id))"
                    hide-details
                    outlined
                    readonly
                    filled
                    dense
                  ></v-autocomplete>
                  <template v-else>
                    <v-autocomplete
                      v-if="p_editar"
                      v-model="usuario_asignado"
                      item-value="id"
                      item-text="nombre"
                      :items="usuarios_asignar.filter(us => (us.inhabilitado != 1 || us.id == usuario_asignado) && us.empresas.find(em => em == recupero.empresa_id))"
                      :clearable="editar"
                      :readonly="!editar"
                      :filled="!editar"
                      hide-details
                      outlined
                      dense
                    ></v-autocomplete>
                    <strong v-else>{{ usuario_nombre }}</strong>
                  </template>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-row>
                <v-col cols="3" sm="4" class="pr-0 pl-sm-3 pl-0">
                  Monto Empresa
                </v-col>
                <v-col cols="8" sm="6">
                  <TextFieldMoney
                    v-model="monto_empresa"
                    v-bind:properties="{
                      'prefix': '$',
                      'readonly': !editar || estado == 5,
                      'filled': !editar || estado == 5
                    }"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0 pt-2">
              <v-textarea
                v-model="negociacion"
                label="Negociación"
                :rows="3"
                :rules="editar && estado != 5 && contactar == null ? [$store.state.rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                auto-grow
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pt-0 pb-4">
        <v-checkbox
          v-if="show_contactar"
          v-model="contactar"
          label="Volver a contactar"
          class="mb-1"
          :true-value="1"
          :false-value="null"
          :disabled="!editar"
          hide-details
          dense
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn
          :color="editar ? 'error' : 'secondary'"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          {{ editar ? 'Cancelar' : 'Volver' }}
        </v-btn>
        <BtnConfirmar
          v-if="editar"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { formatMesAño, format_money } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import TextFieldMoney from '../util/TextFieldMoney.vue'

export default {
  data () {
    return {
      formatMesAño: formatMesAño,
      formatMoney: format_money,
      show_contactar: false,
      load: false,
      editar: false,
      estado: null,
      usuario: null,
      usuario_nombre: '',
      usuario_asignado: null,
      monto_empresa: null,
      negociacion: null,
      contactar: null
    }
  },
  props: {
    value: Boolean,
    recupero: Object
  },
  computed: {
    ...mapState('recuperos', ['estados_recupero', 'usuarios_asignar', 'p_editar']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    TextFieldMoney
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.usuario = null
        this.usuario_nombre = ''
        // si no tiene permisos para editar traigo su usuario
        if (!this.p_editar) {
          await new Promise(resolve => setTimeout(resolve, 1))

          this.$store.state.loading = true
          let result = await this.$store.dispatch('recuperos/get_usuario')
          this.$store.state.loading = false

          if (result.exito == 1) {
            this.usuario = result.data.id
            this.usuario_nombre = result.data.nombre
          } else {
            this.dialog = false
            this.$swal.fire({
              icon: 'error',
              title: result.message
            })
          }
        }
        this.setearDatos()
      } else {
        this.editar = false
        this.$refs.form.resetValidation()
      }
    },
    editar (val) {
      if (!val) {
        this.$refs.form.resetValidation()
        this.setearDatos()
      }
    }
  },
  methods: {
    async guardar () {
      if (!this.$refs.form.validate()) return

      const nuevo = this.recupero.recupero_id == null
      let datos = {
        cartera_id: this.recupero.id,
        estado: this.estado,
        usuario_asignado: this.usuario_asignado,
        monto_empresa: this.monto_empresa,
        negociacion: this.negociacion,
        contactar: this.contactar
      }
      
      let negociacion = this.negociacion ? this.negociacion : ''
      let negociacion_recupero = this.recupero.negociacion ? this.recupero.negociacion : ''
      
      // solo si modifico el contactar y el resto es exactamente igual guardo unicamente el contactar
      if (this.estado == this.recupero.estado_id && this.monto_empresa == this.recupero.monto_empresa &&
          negociacion == negociacion_recupero && this.contactar != this.recupero.contactar) {
        
        this.load = true
        let result = await this.$store.dispatch('recuperos/volver_contactar', {
          cartera_id: this.recupero.id,
          usuario_asignado: this.usuario_asignado
        })
        this.load = false

        if (result.exito == 1) {
          this.$emit('contactar', {
            id: this.recupero.id,
            usuario: this.usuario_asignado
          })
          this.dialog = false
          this.$swal.fire({
            icon: 'success',
            title: result.message
          })
        } else {
          this.$swal.fire({
            icon: 'error',
            title: result.message
          })
        }

      } else {
        if (!nuevo) datos.recupero_id = this.recupero.recupero_id

        this.load = true
        let result = await this.$store.dispatch(`recuperos/${nuevo ? 'nuevo' : 'editar'}_recupero`, datos)
        this.load = false

        if (result.exito == 1) {
          datos.id = result.id
          this.$emit('setear', datos)
          this.dialog = false
          this.$swal.fire({
            icon: 'success',
            title: result.message
          })
        } else {
          this.$swal.fire({
            icon: 'error',
            title: result.message
          })
        }
      }
    },
    validarContactar () {
      const estado = this.estados_recupero.find(er => er.id == this.estado)
      if (estado && estado.contactar == 1) {
        this.show_contactar = true
        this.contactar = JSON.parse(JSON.stringify(this.recupero.contactar))
      } else {
        this.show_contactar = false
        this.contactar = null
      }
    },
    copy_clipboard (texto) {
      navigator.clipboard.writeText(texto)
    },
    setearDatos () {
      this.estado = JSON.parse(JSON.stringify(this.recupero.estado_id))
      this.usuario_asignado = this.usuario == null ? JSON.parse(JSON.stringify(this.recupero.usuario_asignado_id)) : this.usuario

      if (this.recupero.estado_id == 1 && (this.recupero.situacion_id == 4 || this.recupero.situacion_id == 6)) {
        this.monto_empresa = null
        this.negociacion = null
      } else {
        this.monto_empresa = JSON.parse(JSON.stringify(this.recupero.monto_recupero))
        this.negociacion = JSON.parse(JSON.stringify(this.recupero.negociacion))
      }
      this.validarContactar()
    }
  }
}
</script>
<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    scrollable
  >
    <v-card :loading="load">
      <v-card-title>
        Historial suscripción {{ preimpreso }}
        <v-spacer></v-spacer>
        <v-row class="d-flex justify-end body-1 mr-0">
          <v-col cols="12" md="8">
            <SearchDataTable
              v-model="search"
            />
          </v-col>
        </v-row>
        <v-divider 
          class="mx-3"
          vertical
        ></v-divider>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
          class="cebra"
          :search="search"
          :items="log"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:[`item.fecha`]="{ value }">
            {{ moment(value).add(3, 'hours').format('DD/MM/YY [-] HH:mm:ss') }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="teal"
          :disabled="load"
          :dark="!load"
          small
          @click="buscar(solo_usuario ? '' : 1)"
        >
          <v-icon small left>fas fa-eye{{ solo_usuario ? '' : '-slash' }}</v-icon>
          {{ solo_usuario ? 'Mostrar' : 'Ocultar' }} registros del sistema
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import SearchDataTable from '../../util/SearchDataTable.vue'

export default {
  data () {
    return {
      moment: moment,
      solo_usuario: true,
      load: false,
      search: '',
      log: [],
      headers: [
        { text: 'Log', value: 'estado_post', sortable: false },
        { text: 'Fecha', value: 'fecha', sortable: false, width: '150' },
        { text: 'Usuario', value: 'usuario', sortable: false, width: '150' }
      ]
    }
  },
  props: {
    value: Boolean,
    preimpreso: Number,
    empresa: Number
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    SearchDataTable
  },
  watch: {
    dialog (val) {
      if (val) {
        this.buscar(1)
      } else {
        this.solo_usuario = true
        this.log = []
      }
    }
  },
  methods: {
    async buscar (solo_usuario) {
      this.solo_usuario = solo_usuario == 1
      this.load = true
      let result = await this.$store.dispatch('mantenimientoPA/get_historial', {
        preimpreso: this.preimpreso,
        empresa: this.empresa,
        solo_usuario: solo_usuario
      })
      this.load = false

      if (result.exito == 1) {
        this.log = result.data
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }
    }
  }
}
</script>
<template>
  <v-dialog v-model="dialog" max-width="670" scrollable>
    <v-card>
      <v-card-title primary-title>
        <div>Objetivos</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <!-- MODAL FILTRO -->
      <v-card-text>
        <v-row class="pt-8 align-center">
          <v-col cols="12" sm="3">
            <v-autocomplete
              v-model="filtro.empresa"
              label="Empresa"
              item-value="id"
              item-text="nombre"
              :items="familias"
              hide-details
              outlined
              dense
              @change="
                set_marca();
                objetivos = [];
              "
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="3">
            <v-autocomplete
              v-model="filtro.marca"
              label="Marca"
              item-value="id"
              item-text="nombre"
              :items="
                empresas.filter((marca) => marca.familia === filtro.empresa)
              "
              hide-details
              outlined
              dense
              @change="objetivos = []"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-btn icon title="Buscar" @click="buscar">
              <v-icon>fas fa-search</v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-col class="ml-4">
            <v-btn
              color="success"
              title="Nuevo"
              small
              fab
              @click="dialog_nuevo = true"
              v-if="filtro.marca"
              ><v-icon>fas fa-plus</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <!-- TABLA AVANCE DESDE TOLERANCIA -->

        <v-data-table
          :headers="headers"
          :items="objetivosFiltrados"
          :items-per-page="-1"
          :loading="load"
          class="elevation-1 mt-4"
          dense
          hide-default-footer
        >
          <template v-slot:[`header.{header.key}`]="{ header }">
            <span v-if="header.key === 'name'">
              <v-icon small>mdi-account</v-icon>
              {{ header.text }}
            </span>
            <span else>
              {{ header.text }}
            </span>
          </template>
          <!-- CAMPO EDITABLE -->
          <template v-slot:item.porcentaje="{ item }">
            <td class="col-3">
              <v-text-field
                name="name"
                v-model="item.porcentaje"
                type="number"
                prefix="%"
                id="id"
                dense
                hide-details
                outlined
                flat
                class="py-1"
                reverse
                @focus="set_porcentaje(item.porcentaje)"
                @blur="actualizar_tolerancia(item)"
              ></v-text-field>
            </td>
          </template>

          <template v-slot:item.accion="{ item }">
            <v-btn
              :disabled="disable_eliminar(item)"
              @click="eliminar_objetivo(item)"
              icon
            >
              <v-icon small color="error">fas fa-trash</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-col class="d-flex justify-end pr-0 pt-4">
          <v-btn color="error" @click="dialog = !dialog"> Cancelar </v-btn>
          <BtnConfirmar
            clase="ml-3"
            :disabled="filtro.marca == null || objetivos.length == 0"
            :texto="`¿Desea guardar los cambios realizados?  
            <br/>
            Las tolerancias modificadas figurarán a partir del periodo ${util_fecha}`"
            @action="guardar_tolerancias()"
          />
        </v-col>
      </v-card-text>
    </v-card>
    <!-- MODAL NUEVO -->
    <v-dialog
      @click:outside="cerrar_dialog_nuevo"
      v-model="dialog_nuevo"
      max-width="650"
      scrollable
    >
      <v-card>
        <v-card-title primary-title>
          <div>Nuevo</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="cerrar_dialog_nuevo">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row class="pt-5">
            <v-col cols="10" sm="3">
              <PeriodoPicker
                v-model="objetivo.vigencia_desde"
                :min="periodo_actual"
                @change="avances_handler"
              />
            </v-col>

            <v-col cols="10" sm="4">
              <v-autocomplete
                v-model="objetivo.avance"
                :items="avances_filtrados"
                label="Avance"
                hide-details
                clearable
                outlined
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="10" sm="4">
              <v-text-field
                v-model="objetivo.porcentaje"
                label="Tolerancia"
                type="number"
                outlined
                dense
                prefix="%"
                reverse
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-end pr-6">
              <v-btn color="error" @click="cerrar_dialog_nuevo">
                Cancelar
              </v-btn>
              <v-btn
                class="ml-3 white--text"
                color="blue"
                @click="agregar_objetivo()"
              >
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import BtnConfirmar from "../../util/BtnConfirmar.vue";
import PeriodoPicker from "../../util/PeriodoPicker.vue";
import { mapState, mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    const util_fecha = moment(new Date()).format("MM/YYYY");
    return {
      load: false,
      disable: false,
      util_fecha: util_fecha,
      buscar_actualizados: true,
      avance: null,
      tolerancia: null,
      avances: [],
      avances_filtrados: [],
      avances_encontrados: [],
      periodo_actual: moment(new Date()).format("YYYY-MM"),
      objetivos: [],
      objetivos_fecha: [],
      obj_tolerancias: {
        tolerancias: [],
      },
      objetivo: {
        id: null,
        avance: null,
        porcentaje: null,
        vigencia_desde: util_fecha,
        accion: null,
      },
      filtro: {
        empresa: null,
        marca: null,
      },
      dialog_nuevo: false,
      acciones: {
        view: "view",
        new: "new",
        update: "update",
        delete: "delete",
      },
      focus_porcentaje: null,
      headers: [
        {
          text: "Avance",
          value: "avance",
          align: "center",
        },
        { text: "Desde", value: "vigencia_desde", align: "start" },
        {
          text: "Tolerancia",
          value: "porcentaje",
          sortable: false,
        },

        {
          text: "Acciones",
          value: "accion",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  created() {
    for (let i = 2; i <= 120; i++) {
      this.avances.push({
        value: i,
        text: "AVANCE " + i,
      });
    }
  },
  computed: {
    ...mapGetters(["una_familia"]),
    ...mapState(["familias", "empresas"]),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    objetivosFiltrados() {
      return this.objetivos.filter((obj) => obj.accion !== "delete");
    },
  },
  methods: {
    async buscar() {
      if (this.filtro.marca == null) {
        return this.$store.dispatch("show_snackbar", {
          text: "Debe seleccionar una empresa y una marca",
          color: "orange",
        });
      }

      this.objetivos = [];

      this.load = true;
      let result = await this.$store.dispatch(
        "dashboardPA/get_objetivos",
        this.filtro
      );
      this.load = false;

      if (result.exito == 1) {
        this.objetivos = result.data;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },

    set_marca() {
      let marca = this.empresas.filter(
        (mar) => mar.familia == this.filtro.empresa
      );
      this.filtro.marca = marca.length == 1 ? marca[0].id : null;
    },

    cerrar_dialog_nuevo() {
      this.dialog_nuevo = false;
      this.objetivo = {
        id: null,
        avance: null,
        porcentaje: null,
        vigencia_desde: this.util_fecha,
        accion: null,
      };
    },

    async agregar_objetivo() {
      if (this.objetivo.avance == null || !this.objetivo.porcentaje) {
        return this.$store.dispatch("show_snackbar", {
          text: "Debe seleccionar un avance y tipear una tolerancia",
          color: "orange",
        });
      }

      this.load = true;
      this.objetivo.id = null;
      this.objetivo.accion = "new";

      // Crear una copia superficial del objetivo
      const nuevoObjetivo = { ...this.objetivo };

      // Inserto el nuevo objetivo en el array armado para enviar como body en la petición
      this.obj_tolerancias.tolerancias.push(nuevoObjetivo);

      // Inserto el nuevo objetivo al array original para poder verlo desde el cliente
      this.objetivos.push(nuevoObjetivo);

      this.load = false;
      this.cerrar_dialog_nuevo();
    },

    async guardar_tolerancias() {
      if (this.obj_tolerancias.tolerancias.length == 0) {
        return this.$store.dispatch("show_snackbar", {
          text: "No hay objetivos para actualizar",
          color: "orange",
        });
      }

      this.load = true;
      let res = await this.$store.dispatch("dashboardPA/post_objetivos", {
        filtro: this.filtro,
        payload: this.obj_tolerancias,
      });

      if (res.exito == 1) {
        this.$emit("buscar", this.buscar_actualizados);

        this.$swal.fire({
          icon: "success",
          title: res.message,
        });
        // TODO: ENVIAR EMIT AL COMPONENTE PADRE DEL TRUE PARA QUE BUSQUE LOS OBJETIVOS ACTUALIZADOS
      }
      if (res.exito == 0) {
        this.load = false;
        return this.$store.dispatch("show_snackbar", {
          text: res.message,
          color: "error",
        });
      }

      this.load = false;

      this.dialog = false;
    },

    set_porcentaje(porcentaje) {
      this.focus_porcentaje = porcentaje;
    },

    actualizar_tolerancia(item) {
      if (this.focus_porcentaje != item.porcentaje) {
        // Si la tolerancia a actualizar se creo recientemente
        if (item.accion == "new") {
          // Busco la referencia de esa tolerancia para luego modificar su porcentaje
          const toleranciaModificar = this.obj_tolerancias.tolerancias.find(
            (el) => el.avance == item.avance
          );
          toleranciaModificar.porcentaje = item.porcentaje;
        } else {
          item.accion = "update";
          this.obj_tolerancias.tolerancias.push(item);
        }

        this.focus_porcentaje = null;
      }
    },

    async eliminar_objetivo(objetivo) {
      if (objetivo.accion == "new") {
        this.obj_tolerancias.tolerancias =
          this.obj_tolerancias.tolerancias.filter((obj) => obj !== objetivo);
        objetivo.accion = "delete";
      } else {
        objetivo.accion = "delete";
        this.obj_tolerancias.tolerancias.push(objetivo);
      }

    },

    disable_eliminar(item) {
      return (
        moment(item.vigencia_desde, "MM/YYYY") <
        moment(this.util_fecha, "MM/YYYY")
      );
    },

    avances_handler() {
      this.objetivo.avance = null;
      const fechaObjetivo = moment(this.objetivo.vigencia_desde, "MM/YYYY");
      const fechaUtil = moment(this.util_fecha, "MM/YYYY");

      if (fechaObjetivo.isAfter(fechaUtil)) {
        this.objetivos_fecha = this.objetivosFiltrados.filter(
          (objetivo) => objetivo.vigencia_desde == this.objetivo.vigencia_desde
        );
        this.avances_encontrados = this.objetivos_fecha.map(
          (objetivo) => objetivo.avance
        );

        this.avances_filtrados = this.avances.filter(
          (valor) => !this.avances_encontrados.includes(valor.value)
        );
      } else {
        this.avances_encontrados = this.objetivosFiltrados.map(
          (objetivo) => objetivo.avance
        );

        this.avances_filtrados = this.avances.filter(
          (valor) => !this.avances_encontrados.includes(valor.value)
        );
      }
    },
  },
  watch: {
    empresa(val) {
      if (val) {
        this.filtro.empresa = val;
      }
    },
    marca(val) {
      if (val) {
        this.filtro.marca = val;
      }
    },

    async dialog_nuevo(val) {
      if (val) {
        if (this.objetivos.length == 0) {
          await this.buscar();
        }
        this.avances_encontrados = this.objetivosFiltrados.map(
          (objetivo) => objetivo.avance
        );

        this.avances_filtrados = this.avances.filter(
          (valor) => !this.avances_encontrados.includes(valor.value)
        );
      }
    },

    dialog(val) {
      if (val) {
        if (this.marca) {
          this.filtro.empresa = this.empresa;
          this.filtro.marca = this.marca;
          this.buscar();
        }
      }
      if (!val) {
        this.filtro.empresa = null;
        this.filtro.marca = null;
        this.objetivos = [];
        this.obj_tolerancias.tolerancias = [];
      }
    },
  },

  props: {
    value: Boolean,
    empresa: Number,
    marca: Number,
    periodo: String,
  },

  components: { BtnConfirmar, PeriodoPicker },
};
</script>

<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.familia"
                    item-value="id"
                    item-text="nombre"
                    :items="familias"
                    hide-details
                    outlined
                    dense
                    @change="set_empresa()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Marca
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-value="id"
                    item-text="nombre"
                    :items="empresas.filter(emp => emp.familia == filtro.familia)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <template v-if="rangoAvance">
                  <v-col cols="6" sm="3" md="2" class="py-1">
                    Avance desde
                    <v-autocomplete
                      v-model="filtro.avance_desde"
                      :items="avances"
                      hide-details
                      clearable
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3" md="2" class="py-1">
                    Avance hasta
                    <v-autocomplete
                      v-model="filtro.avance_hasta"
                      :items="avances"
                      hide-details
                      clearable
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                </template>
                <v-col v-else cols="6" sm="3" md="2" class="py-1">
                  Avance
                  <v-autocomplete
                    v-model="filtro.avance"
                    :items="avances"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Rango
                  <v-checkbox
                    v-model="rangoAvance"
                    class="mt-0"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Suscripción
                  <v-text-field
                    v-model.trim="filtro.preimpreso"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Grupo
                  <v-text-field
                    v-model.trim="filtro.grupo"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Orden
                  <v-text-field
                    v-model.trim="filtro.orden"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-value="id"
                    item-text="nombre"
                    :items="estados_mant"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="4" class="py-1">
                  Usuario asignado
                  <v-autocomplete
                    v-model="filtro.usuario"
                    :items="usuarios_asignar.filter(us => us.inhabilitado != 1 && (us.empresas.find(em => em == filtro.empresa) || filtro.empresa == null))"
                    item-value="id"
                    item-text="nombre"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" class="py-1">
                  Forma de Pago
                  <v-autocomplete
                    v-model="filtro.fp"
                    :items="formas_pago"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="4" md="2" class="py-1">
                  Titularidad
                  <v-autocomplete
                    v-model="filtro.propio"
                    :items="propietarios"
                    hide-details
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="5" sm="4" md="2" class="py-1">
                  <v-checkbox
                    v-model="filtro.alerta"
                    label="Alerta"
                    :true-value="1"
                    :false-value="null"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="7" sm="4" md="3" class="py-1">
                  <v-checkbox
                    v-model="filtro.contactar"
                    label="Volver a contactar"
                    :true-value="1"
                    :false-value="null"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  clase="mt-0"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="preimpreso"
        :headers="headers"
        :items="cartera"
        :loading="load"
        :search="search"
        :item-class="itemRowBackground"
        :footer-props="{
          'items-per-page-options': [10, 50, 100],
          'show-first-last-page': true,
          'show-current-page': true
        }"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <div v-if="p_descargar_excel" class="d-flex">
              <download-excel
                :fetch="getExcel"
                :name="`Cartera Mantenimiento ${moment(new Date()).format('YYYY-MM-DD')}.xls`"
                :fields="headersExcel"
              >
                <v-btn
                  class="mr-2 my-sm-0 my-1 py-sm-0 py-3"
                  title="Descargar Excel"
                  :x-small="$vuetify.breakpoint.xs"
                  :small="!$vuetify.breakpoint.xs"
                  :disabled="cartera.length == 0"
                >
                  Excel
                  <v-icon
                    color="green darken-3"
                    :small="$vuetify.breakpoint.xs"
                    right
                  >
                    fas fa-file-download
                  </v-icon>
                </v-btn>
              </download-excel>
              <download-excel
                type="csv"
                :fetch="getExcel"
                :name="`Cartera Mantenimiento ${moment(new Date()).format('YYYY-MM-DD')}.csv`"
                :fields="headersExcel"
              >
                <v-btn
                  class="mr-2 my-sm-0 my-1 py-sm-0 py-3"
                  title="Descargar CSV"
                  :x-small="$vuetify.breakpoint.xs"
                  :small="!$vuetify.breakpoint.xs"
                  :disabled="cartera.length == 0"
                >
                  CSV
                  <v-icon
                    color="green darken-3"
                    :small="$vuetify.breakpoint.xs"
                    right
                  >
                    fas fa-file-download
                  </v-icon>
                </v-btn>
              </download-excel>

              <div v-if="filtro.familia && p_pagar_cuota">
                <v-btn
                  class="mr-2 my-sm-0 my-1 py-sm-0 py-3"
                  title="SUBIR CSV"
                  :x-small="$vuetify.breakpoint.xs"
                  :small="!$vuetify.breakpoint.xs"
                  @click="abrir_modal_subir_pagos"
                >
                  PAGOS MASIVO
                  <v-icon
                    color="green darken-3"
                    :small="$vuetify.breakpoint.xs"
                    right
                  >
                    fas fa-file-upload
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable v-model="search"/>
            </v-col>
          </v-row>
        </template>
        <!-- campos formateados -->
        <template v-slot:[`item.cuota_vencida`]="{ item }">
          {{ item.cuota_vencida }}
          <v-tooltip
            v-if="item.detalle_impagas != null && item.detalle_impagas != ''"
            right
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="info"
                small
                right
              >
                fas fa-info-circle
              </v-icon>
            </template>
            <span
              v-html="item.detalle_impagas"
            ></span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.plan_propio`]="{ value }">
          <v-tooltip
            v-if="value == 1"
            left
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="primary"
                class="ml-n4"
                small
              >
                fas fa-building
              </v-icon>
            </template>
            <div>
              Plan de título propio
            </div>
          </v-tooltip>
        </template>
        <template v-slot:[`item.existe_sai`]="{ value }">
          <v-tooltip
            v-if="value != 1"
            left
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="orange"
                class="ml-n4"
                small
              >
                fas fa-exclamation-triangle
              </v-icon>
            </template>
            <div>
              Este plan no se encuentra cargado en AutoDealer
            </div>
          </v-tooltip>
        </template>
        <template v-slot:[`item.inaugurado`]="{ value }">
          {{ formatMesAño(value) }}
        </template>
        <template v-slot:[`item.vehiculo`]="{ item }">
          <v-tooltip
            v-if="item.vehiculo != null && item.vehiculo != ''"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="teal"
                small
              >
                fas fa-car
              </v-icon>
            </template>
            <div>
              {{ item.vehiculo }}
            </div>
          </v-tooltip>
          <v-tooltip
            v-if="item.adjudicado != null"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="warning"
                class="ml-2"
                small
              >
                fas fa-key
              </v-icon>
            </template>
            <div>
              {{ item.adjudicado }}
            </div>
          </v-tooltip>
        </template>
        <template v-slot:[`item.adjudicado`]="{ value }">
          <td class="d-none">{{ value }}</td>
        </template>
        <template v-slot:[`item.estado_nombre`]="{ item }">
          <v-chip
            :color="item.estado_color"
            :dark="item.estado_dark == 1"
            :light="item.estado_dark != 1"
            small
          >
            {{ item.estado_nombre }}
          </v-chip>
        </template>
        <template v-slot:[`item.usuario_asignado_nombre`]="{ value }">
          <v-icon v-if="value != null && value != ''" color="success" small>fas fa-check-circle</v-icon>
        </template>
        <!-- acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_dialog(item, 'dialog_detalle')"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            v-if="p_editar"
            color="purple"
            title="Asignar usuario"
            class="mr-2"
            small
            @click="open_dialog(item, 'dialog_asignar')"
          >
            fas fa-user-plus
          </v-icon>
          <v-icon
            v-if="p_pagar_cuota"
            color="green darken-2"
            title="Pagos de cuotas"
            class="mr-2"
            small
            @click="open_dialog(item, 'dialog_pagar_cuota')"
          >
            fas fa-hand-holding-usd
          </v-icon>
          <!-- alertas -->
          <v-tooltip
            v-if="item.contactar == 1"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="orange"
                class="mr-2"
                small
              >
                fas fa-headset
              </v-icon>
            </template>
            <div>
              Volver a contactar
            </div>
          </v-tooltip>
          <AccionAlerta :item="item"/>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- botones -->
    <v-col cols="12" class="d-flex justify-end pt-0">
      <v-btn
        v-if="p_editar"
        color="purple"
        :disabled="filtro.empresa == null"
        :dark="filtro.empresa != null"
        @click="dialog_asignar_masivo = true"
      >
        <v-icon left>fas fa-user-plus</v-icon>
        Asignación Masiva
      </v-btn>
    </v-col>
    <!-- componentes -->
    <DetalleMant
      v-model="dialog_detalle"
      :detalle="item"
      @setear="setDetalle"
    />
    <PagarCuotas
      v-model="dialog_pagar_cuota"
      :detalle="item"
    />
    <Asignar
      v-model="dialog_asignar"
      :item="item"
      @asignar="asignar"
    />
    <AsignarMasivo
      v-model="dialog_asignar_masivo"
      :empresa="filtro.empresa"
      @refrescar="buscar()"
    />
    <PagosMasivo :Empresa_id="filtro.familia" v-model="modalPagosMasivo" />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formatMesAño, getNombreObjArray } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import SearchDataTable from '../../components/util/SearchDataTable'
import DetalleMant from '../../components/planes/DetalleMant.vue'
import PagarCuotas from '../../components/planes/PagarCuotas.vue'
import AccionAlerta from '../../components/planes/AccionAlerta.vue'
import Asignar from '../../components/planes/mantenimiento/Asignar.vue'
import AsignarMasivo from '../../components/planes/mantenimiento/AsignarMasivo.vue'
import moment from 'moment'
import PagosMasivo from '../../components/planes/mantenimiento/pagosMasivo.vue'

export default {
  data () {
    return {
      formatMesAño: formatMesAño,
      moment: moment,
      panel: 0,
      load: false,
      rangoAvance: false,
      dialog_asignar: false,
      dialog_detalle: false,
      dialog_pagar_cuota: false,
      dialog_asignar_masivo: false,
      search: '',
      item: {},
      formas_pago: [
        { value: 'DC', text: 'Débito en Cuenta' },
        { value: 'TC', text: 'Tarjeta de Crédito' },
        { value: 'TR', text: 'Tradicional' }
      ],
      propietarios: [
        { value: 0, text: 'Clientes' },
        { value: 1, text: 'De título propio' },
        { value: null, text: 'Todos' }
      ],
      avances: [],
      cartera: [],
      headers: [
        { text: 'Suscripción', value: 'preimpreso', align: 'right' },
        { text: 'Grupo/Orden', value: 'control_gr', sortable: false },
        { text: 'Avance', value: 'avance', align: 'center' },
        { text: 'Impagas', value: 'cuota_vencida', align: 'center' },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Situación', value: 'situacion_xpansiva' },
        { text: 'Titular', value: 'titular' },
        { text: 'Forma Pago', value: 'forma_pago' },
        { text: 'Marca', value: 'empresa_nombre' },
        { text: 'Vehículo', value: 'vehiculo', sortable: false },
        { text: 'Asignado', value: 'usuario_asignado_nombre', align: 'center' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
        { text: '', value: 'existe_sai', align: 'center', width: '1' },
        { text: '', value: 'plan_propio', align: 'center', width: '1' },
        { text: '', value: 'adjudicado', class: 'd-none', sortable: false }
      ],
      headersExcel: {
        'Preimpreso': 'preimpreso',
        'Grupo': 'grupo',
        'Orden': 'orden',
        'Avance': 'avance',
        'Impagas': 'cuota_vencida',
        'Inaugurado': 'inaugurado',
        'Estado': 'estado_nombre',
        'Tipo estado': 'estado_automatico',
        'Titular': 'titular',
        'Forma Pago': 'forma_pago',
        'Marca': 'marca_nombre',
        'Vehículo': 'vehiculo',
        'Adjudicado': 'adjudicado',
        'Asignado a': 'usuario_asignado_nombre',
        'Alerta': 'alerta',
        'En AutoDealer': 'existe_sai',
        'Última fecha actualiz.' :'fecha_actualiz',
        'Situación Xpansiva' :'situacion_xpansiva',
        'Usuario log' :'usuario_log',
        'Fecha log' :'fecha_log',
        'Estado post' :'estado_log',
        'Comentario' :'ultimo_comentario',
        'Fecha comentario' :'fecha_ultimo_comentario',
        'Usuario comentario' :'usuario_ultimo_comentario',
        'Comentario del periodo' :'ultimo_comentario_periodo',
        'Periodo' :'fecha_ultimo_comentario_periodo',
        'Usuario del periodo' :'usuario_ultimo_comentario_periodo'
      },
      filtroSave: {},
      filtro: {
        familia: null,
        empresa: null,
        avance: null,
        avance_desde: null,
        avance_hasta: null,
        preimpreso: '',
        grupo: '',
        orden: '',
        estado: null,
        usuario: null,
        fp: null,
        propio: null,
        alerta: null,
        contactar: null
      },

      modalPagosMasivo: false
    }
  },
  async created () {
    // si tiene solo 1 familia la selecciono
    if (this.una_familia) this.filtro.familia = this.familias[0].id
    // si tiene solo 1 empresa la selecciono
    this.set_empresa()
    for (let i = 2; i <= 120; i++) {
      this.avances.push({
        value: i,
        text: 'CUOTA ' + i
      })
    }
    this.$store.state.loading = true
    await this.$store.dispatch('mantenimientoPA/get_estados')
    await this.$store.dispatch('mantenimientoPA/get_usuarios')
    await this.$store.dispatch('recuperos/get_permiso_editar')
    await this.$store.dispatch('mantenimientoPA/get_permiso_descargar_excel')
    await this.$store.dispatch('mantenimientoPA/get_motivos')
    this.$store.state.loading = false
  },
  computed: {
    ...mapGetters(['una_familia']),
    ...mapState(['empresas', 'familias']),
    ...mapState('recuperos', ['p_editar']),
    ...mapState('mantenimientoPA', ['estados_mant', 'usuarios_asignar', 'p_pagar_cuota', 'p_descargar_excel'])
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    DetalleMant,
    PagarCuotas,
    AccionAlerta,
    Asignar,
    AsignarMasivo,
    PagosMasivo
  },
  watch: {
    rangoAvance (val) {
      this.filtro.avance = null
      if (val) {
        this.filtro.avance_desde = 2
        this.filtro.avance_hasta = 13
      } else {
        this.filtro.avance_desde = null
        this.filtro.avance_hasta = null
      }
    }
  },
  mounted() {
    if(this.$route.query.empresa && this.$route.query.marca && this.$route.query.avance){
      const empresa = parseInt(this.$route.query.empresa);
      const marca = parseInt(this.$route.query.marca);
      const avance = parseInt(this.$route.query.avance);

      this.filtro.familia = empresa
      this.filtro.empresa = marca
      this.filtro.avance = avance

      this.buscar()
    }
  },

  methods: {
    async getExcel () {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('mantenimientoPA/get_excel', this.filtroSave)
      this.$store.state.loading = false

      if (result.exito == 1) {
        return result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async buscar () {
      if (!this.filtro.familia) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'orange'
        })
      }

      let header = this.headers.find(he => he.value == 'empresa_nombre')
      if (this.filtro.empresa && header) {
        this.headers.splice(8, 1)
      } else if (!this.filtro.empresa && !header) {
        this.headers.splice(8, 0, { text: 'Marca', value: 'empresa_nombre' })
      }

      this.filtroSave = JSON.parse(JSON.stringify(this.filtro))
      this.cartera = []
      this.search = ''

      this.load = true
      let result = await this.$store.dispatch('mantenimientoPA/get_cartera', this.filtro)
      this.load = false

      if (result.exito == 1) {
        this.cartera = result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    asignar (result) {
      let cartera = this.cartera.find(ca => ca.id == result.id)
      cartera.usuario_asignado_id = result.usuario
      cartera.usuario_asignado_nombre = getNombreObjArray(this.usuarios_asignar, 'id', result.usuario)
    },
    setDetalle (detalle) {
      let cartera = this.cartera.find(ca => ca.id == detalle.cartera_id)
      cartera.motivo_np = detalle.motivo
      cartera.estado_id = detalle.estado
      cartera.estado_nombre = getNombreObjArray(this.estados_mant, 'id', detalle.estado)
      cartera.estado_color = getNombreObjArray(this.estados_mant, 'id', detalle.estado, 'color')
      cartera.estado_dark = getNombreObjArray(this.estados_mant, 'id', detalle.estado, 'dark')
      cartera.usuario_asignado_id = detalle.usuario_asignado
      cartera.usuario_asignado_nombre = getNombreObjArray(this.usuarios_asignar, 'id', detalle.usuario_asignado)
      cartera.contactar = detalle.contactar
    },
    open_dialog (item, dialog) {
      this.item = JSON.parse(JSON.stringify(item))
      this[dialog] = true
    },
    itemRowBackground (item) {
      return item.alerta == 1 ? 'background-color: rowerr' : ''
    },
    set_empresa () {
      let empresa = this.empresas.filter(emp => emp.familia == this.filtro.familia)
      this.filtro.empresa = empresa.length == 1 ? empresa[0].id : null
    },
    limpiar () {
      this.filtro = {
        familia: null,
        empresa: null,
        avance: null,
        avance_desde: null,
        avance_hasta: null,
        preimpreso: '',
        grupo: '',
        orden: '',
        estado: null,
        usuario: null,
        fp: null,
        propio: null,
        alerta: null,
        contactar: null
      }
    },

    abrir_modal_subir_pagos(){
      this.modalPagosMasivo = true
    }
  }
}
</script>
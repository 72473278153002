<template>
  <v-dialog v-model="dialog" width="900" scrollable>
    <v-card>
      <v-card-title>
        <span :class="$vuetify.breakpoint.xs ? 'body-1' : ''">
          Suscripción {{ detalle.preimpreso }} - {{ detalle.grupo }}/{{
            detalle.orden
          }}
        </span>
        <v-spacer></v-spacer>
        <v-chip
          :color="detalle.estado_color"
          :dark="detalle.estado_dark == 1"
          :light="detalle.estado_dark != 1"
        >
          {{ detalle.estado_nombre }}
        </v-chip>
        <v-divider class="mx-3" vertical></v-divider>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-4 body-1">
          <v-col cols="12" sm="6" class="py-1">
            <v-row>
              <v-col cols="3" class="pr-0 pl-md-3 pl-0"> Titular </v-col>
              <v-col cols="9">
                <strong>{{ detalle.titular }}</strong>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="py-1">
            <v-row>
              <v-col cols="3" sm="4" class="pr-0 pl-md-3 pl-0">
                Mes Inaug.
              </v-col>
              <v-col cols="9" sm="8">
                <strong>{{ formatMesAño(detalle.inaugurado) }}</strong>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="py-1">
            <v-row>
              <v-col cols="3" class="pr-0 pl-md-3 pl-0"> Vehículo </v-col>
              <v-col cols="9">
                <strong>{{ detalle.vehiculo }}</strong>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="py-1">
            <v-row>
              <v-col cols="7">
                <v-row>
                  <v-col cols="5" sm="5" md="7" class="pr-0 pl-md-3 pl-0">
                    Avance
                  </v-col>
                  <v-col cols="7" md="5" class="pl-2 pr-0">
                    <strong>{{ detalle.avance_nombre }}</strong>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="p_pagar_cuota" cols="5">
                <v-row>
                  <v-col cols="7" md="5" class="px-0 ml-md-8"> Impagas </v-col>
                  <v-col cols="5" md="4">
                    <strong>{{ detalle.cuota_vencida }}</strong>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="py-1">
            <v-row>
              <v-col cols="3" class="pr-0 pl-md-3 pl-0"> Forma Pago </v-col>
              <v-col cols="9">
                <strong>{{ detalle.forma_pago }}</strong>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="pt-2">
            <v-row v-if="p_pagar_cuota">
              <v-col cols="4" class="pr-0 pl-md-3 pl-0"> Saldo deudor </v-col>
              <v-col cols="8">
                <strong>{{ formatMoney(detalle.saldo_deudor) }}</strong>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="3" md="4" class="pr-0 pl-md-3 pl-0"> Impagas </v-col>
              <v-col cols="4">
                <strong>{{ detalle.cuota_vencida }}</strong>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table
          class="elevation-2 mt-4"
          :loading="load"
          :items="pagos"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:top>
            <div class="d-flex mx-2">
              <span class="body-1 pt-2">
                Pagos de cuotas realizados por
                {{
                  familias.find((fam) => fam.id == detalle.familia_id)
                    ? familias.find((fam) => fam.id == detalle.familia_id)
                        .nombre
                    : ""
                }}
              </span>
              <v-spacer></v-spacer>
              <v-btn
                v-if="detalle.permite_pago == 1 && p_pagar_cuota"
                color="success"
                class="mt-2"
                small
                @click="dialog_pago = true"
              >
                Nuevo pago
                <v-icon right small>fas fa-plus</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.saldo_deudor`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.monto_empresa`]="{ value }">
            {{ formatMoney(value) }}
          </template>
          <template v-slot:[`item.observaciones`]="{ value }">
            <v-icon
              v-if="value != null && value != ''"
              color="info"
              title="Ver observación"
              small
              @click="
                $swal.fire({
                  icon: 'info',
                  text: value,
                })
              "
            >
              fas fa-comment-dots
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron pagos
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pt-0 pb-4">
        <v-btn color="secondary" class="mr-2" @click="dialog = false">
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- modal de pagos -->
    <v-dialog v-model="dialog_pago" width="500" scrollable>
      <v-card>
        <v-card-title>
          Nuevo pago
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog_pago = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-form ref="form">
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="cuotas"
                  label="Cant. cuotas"
                  type="number"
                  :rules="[$store.state.rules.required]"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <TextFieldMoney
                  v-model="monto"
                  label="Monto empresa"
                  v-bind:properties="{
                    prefix: '$',
                  }"
                />
              </v-col>
              <v-col cols="6" sm="4">
                <PeriodoPicker v-model="periodo" :label="true" hideDetails />
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-textarea
                  v-model.trim="observaciones"
                  label="Observaciones"
                  rows="3"
                  hide-details
                  auto-grow
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pt-0 pb-4">
          <v-btn color="error" class="mr-2" @click="dialog_pago = false">
            Cancelar
          </v-btn>
          <BtnConfirmar
            :disabled="monto == null"
            :texto="`¿Desea agregar un nuevo pago de la empresa por el valor de <strong>${formatMoney(
              monto
            )}</strong>?`"
            @action="guardar()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { formatMesAño, format_money } from "../../util/utils";
import BtnConfirmar from "../util/BtnConfirmar.vue";
import TextFieldMoney from "../util/TextFieldMoney.vue";
import PeriodoPicker from "../util/PeriodoPicker.vue";

export default {
  data() {
    return {
      formatMesAño: formatMesAño,
      formatMoney: format_money,
      load: false,
      dialog_pago: false,
      cuotas: null,
      monto: null,
      periodo: null,
      // TODO: ENVIAR PERIODO AL GUARDAR NUEVO PAGO
      observaciones: null,
      pagos: [],
      headers: [
        {
          text: "Usuario",
          value: "usuario",
          sortable: false,
          filterable: false,
        },
        { text: "Fecha", value: "fecha", sortable: false, filterable: false },
        { text: "Avance", value: "avance", sortable: false, filterable: false },
        {
          text: "Impagas",
          value: "cuotas_impagas",
          sortable: false,
          filterable: false,
        },
        {
          text: "Saldo deudor",
          value: "saldo_deudor",
          sortable: false,
          filterable: false,
        },
        {
          text: "Monto pagado",
          value: "monto_empresa",
          sortable: false,
          filterable: false,
        },
        {
          text: "Cant. pagada",
          value: "cantidad",
          sortable: false,
          filterable: false,
        },
        {
          text: "Observaciones",
          value: "observaciones",
          sortable: false,
          filterable: false,
          align: "center",
        },
      ],
    };
  },
  async created() {
    this.$store.state.loading = true;
    await this.$store.dispatch("mantenimientoPA/get_permiso_pagar_cuota");
    this.$store.state.loading = false;
  },
  props: {
    value: Boolean,
    detalle: Object,
  },
  computed: {
    ...mapState(["familias"]),
    ...mapState("mantenimientoPA", ["p_pagar_cuota"]),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  components: {
    BtnConfirmar,
    TextFieldMoney,
    PeriodoPicker
  },
  watch: {
    dialog(val) {
      if (val) {
        this.get_pagos();
      } else {
        this.monto = null;
        this.cuotas = null;
        this.observaciones = null;
        this.periodo = null;
      }
    },
    dialog_pago(val) {
      if (val) {
        this.monto = JSON.parse(JSON.stringify(this.detalle.saldo_deudor));
        this.cuotas = JSON.parse(JSON.stringify(this.detalle.cuota_vencida));
        this.observaciones = null;
      } else {
        this.$refs.form.resetValidation();
      }
    },
  },
  methods: {
    async guardar() {
      if (!this.$refs.form.validate()) return;

      if (parseFloat(this.cuotas) <= 0) {
        return this.$store.dispatch("show_snackbar", {
          text: "Las cuotas no pueden ser menor o igual que 0",
          color: "orange",
        });
      }

      if (parseFloat(this.monto) <= 0) {
        return this.$store.dispatch("show_snackbar", {
          text: "El monto no puede ser menor o igual que 0",
          color: "orange",
        });
      }

      this.load = true;
      let result = await this.$store.dispatch("mantenimientoPA/nuevo_pago", {
        id: this.detalle.id,
        cuotas: this.cuotas,
        monto: this.monto,
        observaciones: this.observaciones,
        periodo: this.periodo
      });
      this.load = false;

      if (result.exito == 1) {
        this.get_pagos();
        this.dialog_pago = false;
        this.$swal.fire({
          icon: "success",
          title: result.message,
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    },
    async get_pagos() {
      this.pagos = [];

      this.load = true;
      let result = await this.$store.dispatch(
        "mantenimientoPA/get_pagos",
        this.detalle.id
      );
      this.load = false;

      if (result.exito == 1) {
        this.pagos = result.data;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="dialog"
    width="350"
  >
    <v-card>
      <v-card-title>
        Descargar Excel
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="mx-16 mt-4">
          Periodo
          <PeriodoPicker
            v-model="periodo"
          />
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          @click="descargar()"
        >
          <v-icon left>fas fa-download</v-icon>
          Descargar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { parseDate } from '../../util/utils'
import moment from 'moment'
import download from 'downloadjs'
import PeriodoPicker from '../util/PeriodoPicker'

export default {
  data () {
    return {
      periodo: null
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    PeriodoPicker
  },
  watch: {
    dialog (val) {
      if (val) {
        this.periodo = moment().startOf('month').subtract(1, 'month').format('MM/YYYY')
      } else {
        this.periodo = null
      }
    }
  },
  methods: {
    async descargar () {
      const periodo = this.periodo
      this.dialog = false
      this.$store.state.loading = true
      let result = await this.$store.dispatch('ventasPA/get_excel', periodo)
      this.$store.state.loading = false

      if (result.exito == 1) {
        download(result.data, `Ventas PA ${moment(parseDate(`01/${periodo}`)).format('MM-YYYY')}.xls`, 'application/vnd.ms-excel')
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    }
  }
}
</script>